<template>
  <!-- 设备基本信息 -->
  <div id="app">
    <!-- 设备基本信息 -->
    <div class="container">
      <dv-border-box-2 style="padding:10px;box-sizing:border-box" :color="['cadetblue', 'cadetblue']">
        <el-row :gutter="10">
          <el-col :span="6" class="title">设备信息</el-col>
          <el-button size="small" plain icon="el-icon-location-outline" type="primary" @click="turnToMap" class="openBtn">查看地图</el-button>
          <el-button size="small" plain icon="el-icon-coordinate" type="success" @click="turnToCRT" class="openBtn">查看CRT</el-button>
          <el-button size="small" plain icon="el-icon-coordinate" :type="videoBtn?'success':'warning'" @click="checkVidevo" class="openBtn">查看视频</el-button>
          <!-- 设备地图信息 -->
          <el-drawer :visible.sync="drawer" direction="rtl" size="100%">
            <!-- <el-button type="primary" @click="drawer=false" class="closeBtn" size="small">关闭</el-button> -->
            <div><i class="el-icon-circle-close closeBtn" @click="drawer=false"></i></div>
            <DeviceMap :position="position" />
          </el-drawer>
          <!-- ########### -->
        </el-row>
        <dv-decoration-3 style="width:250px;height:10px;" />
        <div :gutter="10" class="content">
          <!-- 设备图片 -->
          <dv-border-box-13 class="device-img" :color="['cadetblue', 'cadetblue']">
            <div class="img-cont">
              <el-image title="查看大图" class="img" :src="url" :preview-src-list="srcList">
                <div slot="error" class="img-error">
                  <div class="img-error">图片未上传!</div>
                </div>
              </el-image>
            </div>
          </dv-border-box-13>
          <!-- 设备信息 -->
          <dv-border-box-5 class="device-info" :color="['cadetblue', 'cadetblue']">
            <div class="device-info-item">设备编号：{{deviceInfo.id}}</div>
            <div class="device-info-item">设备类型：{{deviceInfo.typeName}}</div>
            <div class="device-info-item" v-for="(item, index) in deviceInfo.device" v-if="item.proName.indexOf('绑定视频')==-1 &&
                 item.proName.indexOf('设备ID')==-1 &&
                 item.proName.indexOf('设备图片')==-1">
              {{
                item.proName=='所属系统'?'系统':
                item.proName=='绑定公司'?'单位':
                item.proName=='绑定网关'?'网关':
                item.proName=='所属区域'?'区域':
                item.proName}}：{{item.value}}
            </div>
          </dv-border-box-5>

        </div>
      </dv-border-box-2>
    </div>
  </div>
</template>

<script>
import crtDeviceCoordinate from "@/api/manageApi/CrtDeviceCoordinate";

import deviceInfoManagement from "@/api/manageApi/DeviceInfoManagement.js";

import companySystem from "@/api/managementApi/CompanyInfo";

import noticeSysManagement from "@/api/managementApi/NoticeSysManagement";

import BaseData from '@/assets/config/BaseData'

// 设备地图
import DeviceMap from '@/components/ModuleManage/DeviceMap'

// 查设备经纬度
import DeviceTypeAboutMap from '@/api/manageApi/DeviceTypeAboutMap'

export default {
  name: 'DeviceBaseInfo',
  props: ["deviceInfos"],
  components: {
    DeviceMap,
  },
  data() {
    return {
      videoBtn: false,
      videoUrl: '',
      isTrue: false,
      baseUrl: BaseData.baseUrl,
      //   activeName: '1',
      companyList: [],
      systemList: [],

      // 查询的单个设备信息
      //   deviceInfoList: [],

      // 图片路径
      url: '',
      srcList: [],

      drawer: false,

      position: {},

      deviceInfo: {},

    }
  },
  // 监听数据是否发生改变,如果改变,刷新数据
  watch: {
    deviceInfos(newVal, oldVal) {
      if (newVal.id != oldVal.id) {
        this.deviceInfo = newVal
        this.videoUrl = '';
        // 86185404712011
        console.log(this.deviceInfo.videoUrl);
        if (this.deviceInfo.videoUrl != null) {
          this.videoBtn = true
          this.videoUrl = this.deviceInfo.videoUrl
        } else {
          this.videoBtn = false
        }
        this.getCompanyAndSystem();
      }
    }
  },

  created() {
    this.deviceInfo = this.deviceInfos;
    // this.deviceInfoList = this.deviceInfo.device
    this.getCompanyAndSystem();

  },

  methods: {
    // 地图显示
    turnToMap() {
      DeviceTypeAboutMap.getOneDeviceTypeAboutMap(this.deviceInfo.id).then(res => {
        if (res.data.data.list.length == 0) {
          this.$message.warning("该设备尚未添加位置信息！")
        } else {
          this.drawer = true;
          this.position.lng = res.data.data.list[0].lng
          this.position.lat = res.data.data.list[0].lat
        }
      });
    },

    // 查看CRT
    turnToCRT() {
      crtDeviceCoordinate.getCoordinateByDeviceId(this.deviceInfo.id).then((res) => {
        // console.log(res);
        if (res.data.message != "失败") {
          this.$router.push({
            name: "CRTShow",
            params: {
              CRTId: res.data.data.data.crtPictureId,
              deviceId: res.data.data.data.deviceInfoId,
            },
          });
        } else {
          this.$message({
            type: "warning",
            message: "该设备尚未添加点位！",
          });
        }
      });
    },
    // 查看视频
    async checkVidevo() {
      console.log(this.deviceInfo)
      if (this.deviceInfo.typeId == "YSVideo") {
        this.$router.push({
          path: '/VideoPlayback',
          query: {
            rowInfo: this.deviceInfo
          }
        });
      } else {
        if (this.deviceInfo.typeId == "video") {
          const { data: res1 } = await HomePage.viewVideo(this.deviceInfo.id)
          console.log(res1);
          if (res1.data.videoUrl != null) {
            window.location.href = res1.data.videoUrl;
          } else {
            this.$message({
              type: "error",
              message: "该设备未绑定任何摄像头！",
            });
          }
        } else {
          if (this.deviceInfo.videoUrl) {
            window.location.href = this.deviceInfo.videoUrl;
          } else {
            this.$message({
              type: "warning",
              message: "该设备未绑定视频设备！",
            });
          }
        }
      }

      // if (this.videoUrl!='') {
      //   window.location.href = this.videoUrl;
      // } else {
      //   this.$message({
      //     type: "error",
      //     message: "该设备未绑定任何摄像头！",
      //   });
      // }
    },


    // 所有公司
    async getCompanyAndSystem() {
      this.url = ''
      this.srcList = []
      // 公司
      const { data: company } = await companySystem.getCompanyInfo()
      this.companyList = company.data.list;

      // 系统
      const { data: system } = await noticeSysManagement.getNotifyBigSystemById()
      this.systemList = system.data.list;


      this.dataConversion()
    },
    dataConversion() {
      for (let i = 0; i < this.deviceInfo.device.length; i++) {
        // 显示设备图片
        // 烟感设备图
        if (this.deviceInfo.typeName.indexOf('烟感') != -1) {
          this.url = this.baseUrl + "deviceInfoIcon/电信无线烟感.png"
          this.srcList.push(this.url)
        } else if (this.deviceInfo.device[i].proName == "设备图片") {
          this.url = this.baseUrl + this.deviceInfo.device[i].value
          this.srcList.push(this.url)
        }


        if (this.deviceInfo.device[i].proName == "布防状态") {
          if (this.deviceInfo.device[i].value == "0") {
            this.deviceInfo.device[i].value = "不布防"
          } else if (this.deviceInfo.device[i].value == "1") {
            this.deviceInfo.device[i].value = "布防"
          }
        }
        if (this.deviceInfo.device[i].proName == "是否启用") {
          if (this.deviceInfo.device[i].value == "0") {
            this.deviceInfo.device[i].value = "否"
          } else if (this.deviceInfo.device[i].value == "1") {
            this.deviceInfo.device[i].value = "是"
          }
        }
        if (this.deviceInfo.device[i].proName == "微信通知" ||
          this.deviceInfo.device[i].proName == "语音通知" ||
          this.deviceInfo.device[i].proName == "短信通知") {
          if (this.deviceInfo.device[i].value == "0") {
            this.deviceInfo.device[i].value = "关闭"
          } else if (this.deviceInfo.device[i].value == "1") {
            this.deviceInfo.device[i].value = "开启"
          }
        }
        if (this.deviceInfo.device[i].proName == "所属系统") {
          for (let j = 0; j < this.systemList.length; j++) {
            if (this.deviceInfo.device[i].value == this.systemList[j].id) {
              this.deviceInfo.device[i].value = this.systemList[j].bigSystemName
              break;
            }
          }
        }
        if (this.deviceInfo.device[i].proName == "绑定公司") {
          for (let j = 0; j < this.companyList.length; j++) {
            if (this.deviceInfo.device[i].value == this.companyList[j].id) {
              this.deviceInfo.device[i].value = this.companyList[j].name
            }
          }
        }
      }
    },
  }

}
</script>

<style lang="scss" scoped>
.container {
  font-family: "宋体";
  width: 100%;
  // 信息标题
  .title {
    margin: 5px 0;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 2px;
    color: #a2edf7;
  }
  .title::before {
    content: "丨";
    font-size: 18px;
    font-weight: 600;
  }

  //   信息内容
  .content {
    width: 100%;
    height: 200px;
    box-sizing: border-box;
    padding: 10px;
    display: flex;

    .device-img {
      width: 20%;
      height: 100%;
      margin-right: 10px;
      box-sizing: border-box;
      .img-cont {
        // width: 100%;
        height: 100%;
        padding: 10px;
        box-sizing: border-box;

        /deep/.img-error {
          color: rgba(62, 191, 196, 0.747);
          font-weight: 600;
          letter-spacing: 2px;
          text-align: center;
          line-height: 140px;
          user-select: none;
        }
        .img {
          display: inline-block;
          margin: 10px 30px;
          height: 90%;
        }
      }
    }
    .device-info {
      box-sizing: border-box;
      padding: 20px;
      width: 80%;
      height: 100%;

      .device-info-item {
        box-sizing: border-box;
        padding-right: 10px;
        display: inline-block;
        font-size: 16px;
        margin-right: 60px;
        height: 40px;
        line-height: 50px;
        color: #a2edf7;
        font-weight: 600;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
.closeBtn {
  position: absolute;
  right: 5px;
  top: 15px;
  z-index: 1;
  color: rgba(0, 0, 0, 0.7);
  font-size: 30px;
  font-weight: 600;
  cursor: pointer;
}
.closeBtn:hover {
  color: #1a4109;
}
.openBtn {
  float: right;
  margin: 0 30px 0 0;
}

/deep/.el-drawer {
  background: rgba(1, 48, 49, 0.6);
}
/deep/.el-drawer__header {
  display: none;
}
</style>