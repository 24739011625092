<template>
  <!-- 总线物联网4G中继器 -->
  <div id="app">
    <div class="device-info">
      <el-col :span="6" :offset="6" class="device-status">
        <span>主机设备状态：</span>
        <el-tag effect="dark" :type="deviceSta=='报警'?'danger':
                         deviceSta=='故障'?'warning':
                         deviceSta=='失联'?'info':
                         deviceSta=='正常'?'success':''" size="small">{{deviceSta}}</el-tag>
      </el-col>
      <el-col :span="6" :offset="6" class="device-signal-value iconfont icon-iotechxinhao"> 信号值：{{deviceSta=='失联'?'无信号':signalVal}}</el-col>
    </div>

    <div class="content-card">
      <el-card v-for="(item,index) in deviceListBUS" :key="index" class="content-card-item">
        <div class="boxes">
          <div class="img-box">
            <img :src="item.name.indexOf('设备')!=-1?iconList.shebei:
                       item.name.indexOf('井盖')!=-1?iconList.jingai:
                       item.name.indexOf('压力')!=-1?iconList.yali:
                       item.name.indexOf('功率')!=-1?iconList.gonglv:
                       item.name.indexOf('电压')!=-1?iconList.dianya:
                       item.name.indexOf('电流')!=-1?iconList.dianliu:
                       item.name.indexOf('烟感')!=-1?iconList.yangan:
                       item.name.indexOf('信号')!=-1?iconList.xinhao:
                       item.name.indexOf('电池')!=-1?iconList.dianchidianya:iconList.shebei
              ">
          </div>
          <!-- 设备状态 -->
          <div class="text-box">
            <div class="show-info">
              {{item.name}}：
              <el-tag :type="deviceSta=='失联'?'info':
                    item.status=='正常'?'success':
                    item.status=='故障'?'warning':
                    item.status=='报警'||item.status=='火警'?'danger':
                    item.status=='失联'?'':'info'" size="mini">{{deviceSta=='失联'?'失联':item.status}}
              </el-tag>
            </div>
            <div v-if="item.name.indexOf('输出')!=-1||item.name.indexOf('声光')!=-1">
              <el-button @click="sendCommand(item.name.substring(0,2)+'设备启动')" type="success" size="mini">启动</el-button>
              <span style="display:inline-block;width:20px"></span>
              <el-button @click="sendCommand(item.name.substring(0,2)+'设备停止')" type="primary" size="mini">停止</el-button>
            </div>
          </div>
        </div>
      </el-card>
    </div>

    <!-- 设备操作 -->
    <dv-border-box-13 class="operation" :color="['cadetblue', 'cadetblue']">
      <div class="title1">操作</div>
      <div>
        <div style="height:50px;width:100%" v-if="deviceCommands.length!=0">
          <el-button style="margin:5px" type="success" v-for="item in deviceCommands" v-if="item.contentType!='commandData'" size="small" @click="sendCommand(item)">{{item.commandName}}</el-button>
          <el-button style="margin:5px" type="primary" v-for="item in deviceCommands" v-if="item.contentType=='commandData'" size="small" @click="sendCommand(item)">{{item.commandName}}</el-button>
        </div>

        <el-dialog :title="commandTitle" :visible.sync="showCommand" width="25%" style="top:15%;background:rgba(0,0,0,0.4)">
          <div>
            <el-input v-model="commandData" placeholder="请输入指令内容"></el-input>
          </div>
          <div slot="footer">
            <el-button @click="showCommand = false">取 消</el-button>
            <el-button type="primary" @click="submitCommand">确 定</el-button>
          </div>
        </el-dialog>
      </div>

    </dv-border-box-13>

  </div>
</template>

<script>
import Command from '@/api/mapBuidingApi/Command'

import deviceInfoManagement from "@/api/manageApi/DeviceInfoManagement.js";

export default {
  name: 'BusIot4GRepeater',
  props: ["deviceInfo"],
  data() {
    return {
      // 总线设备
      deviceListBUS: [
        { name: '', status: '', },
        { name: '', status: '', },
        { name: '', status: '', },
        { name: '', status: '', },
        { name: '', status: '', },
        { name: '', status: '', },
        { name: '', status: '', },
        { name: '', status: '', }],

      deviceCommands: [],  // 设备指令
      deviceCommand:{},//设备指令信息
      command: {},
      commandTitle: '',
      showCommand: false,       // 有数据的指令下发
      commandData: '',
      // 心跳周期
      cycleValue: 50,

      //   信号值
      signalVal: '',

      // 设备状态
      deviceSta: '',

      // 动态属性集合
      deviceValueInfoList: [],

      // 智慧用电数据集合
      wisdomElectricity: [],
      timer: null,

      //   // 折线图要传的参数
      //   proList: [],//动态属性
      //   dateList: [], //折线图时间显示数组
      //   value1List: [], //折线图值显示数组
      //   hValue: "", //折线图高阈值显示
      //   lValue: "", //折线图低阈值显示
      //   chartTitle: "",//折线图标题
      //   unit: "",//折线图单位显示
      //   isShowTu: false,//是否显示折线图,
      //   showTips: false,//无折线图时的提示，
      //   showChartDeviceId: "",//显示折线图的设备id
      //   showChartDeviceTypeId: "",//显示折线图的设备类型id
      //   // src\assets\img\icon1\dianchidianya.png
      iconList: {
        yangan: require("@/assets/img/icon1/yangan.png"),
        yali: require("@/assets/img/icon1/yali.png"),
        xinhao: require("@/assets/img/icon1/xinhao.png"),
        shebei: require("@/assets/img/icon1/shebei.png"),
        jingai: require("@/assets/img/icon1/jingai1.png"),
        gonglv: require("@/assets/img/icon1/gonglv.png"),
        dianya: require("@/assets/img/icon1/dianya.png"),
        dianliu: require("@/assets/img/icon1/dianliu.png"),
        dianchidianya: require("@/assets/img/icon1/dianchidianya.png"),
        wengan: require("@/assets/img/icon/wengan.png"),
        shoubao: require("@/assets/img/icon/shoubao.png"),
        shenguang: require("@/assets/img/icon/shenguang.png"),
        shuchu: require("@/assets/img/icon/shuchu.png"),
        default: require("@/assets/img/icon/default.png"),
      },

    }
  },

  created() {
    this.getDeviceValue()
    this.timer= setInterval(() => {
      this.getDeviceValue()
    }, 5000);

    this.getDeviceCommand()
  },
  destroyed() {
    clearInterval(this.timer);
  },

  methods: {

    // 《总线物联网4G中继器》设备数据处理
    async getDeviceValue() {
      const { data: res } = await deviceInfoManagement.getDeviceInfoValueByDeviceId(this.deviceInfo.id)
      this.deviceValueInfoList = res.data.list;

      this.busEquipmentMethod();
    },

    // 总线物联网4G中继器数据处理
    busEquipmentMethod() {
      for (let i = 0; i < this.deviceValueInfoList.length; i++) {
        if (this.deviceValueInfoList[i].id == "失联状态") {
          this.deviceSta = this.deviceValueInfoList[i].value
          console.log(this.deviceSta);
        }
        if (this.deviceValueInfoList[i].id == "信号值") {
          this.signalVal = this.deviceValueInfoList[i].value
        }
        if (this.deviceValueInfoList[i].id == "心跳周期") {
          this.cycleValue = Number(this.deviceValueInfoList[i].value)
          //   console.log(this.cycleValue);
        }
        let num = Number.parseInt(this.deviceValueInfoList[i]["id"].substring(0, 1));
        num--;
        if (this.deviceValueInfoList[i]["id"].indexOf("设备类型") != -1) {
          this.deviceListBUS[num].name = (++num) + "号" + this.deviceValueInfoList[i]["value"]
        }
        if (this.deviceValueInfoList[i]["id"].indexOf('报警状态') != -1) {
          this.deviceListBUS[num].status = "火警";
        } else if (this.deviceValueInfoList[i]["id"].indexOf('故障状态') != -1) {
          this.deviceListBUS[num].status = "故障";
        } else if (this.deviceValueInfoList[i]["id"].indexOf('设备状态') != -1) {
          if (this.deviceValueInfoList[i]["value"].indexOf("解除") != -1 ||
            this.deviceValueInfoList[i]["value"].indexOf("正常") != -1) {
            this.deviceListBUS[num].status = "正常"
          } else if (this.deviceValueInfoList[i]["value"].indexOf("警") != -1) {
            this.deviceListBUS[num].status = "火警"
          } else if (this.deviceValueInfoList[i]["value"].indexOf("故障") != -1) {
            this.deviceListBUS[num].status = "故障"
          } else if (this.deviceValueInfoList[i]["value"].indexOf("暂无") != -1) {
            this.deviceListBUS[num].status = "暂无"
          }
        }
      }
      //   console.log(this.deviceListBUS);
    },
    
    //获取该设备类型所有的指令
    async getDeviceCommand() {
      const { data: res } = await Command.getDeviceCommand(this.deviceInfo.typeId)
      this.deviceCommands = res.data.deviceCommands
    },

    // 有数据的指令下发
    submitCommand() {
      if (this.commandData == '') {
        this.$message.warning("指令内容不能为空！")
      } else {
        this.deviceCommand.paramValue = this.commandData
        this.command['deviceId'] = this.deviceInfo.id;
        this.command['deviceCommand'] = this.deviceCommand
        this.showCommand = false;
        this.commandSend(this.command)
        this.commandData = '';
      }
    },

    //   指令下发
    async sendCommand(item) {
      this.deviceCommand = item
      this.commandTitle = item.commandName;
      this.command['deviceId'] = this.deviceInfo.id;
      if(this.deviceInfo.typeId=="IntelligentFireControlTerminal"&&typeof item=="string"&&item.constructor==String){
        let obj = {}
        obj.contentType = "commandNoData"
        obj.paramValue = item
        this.command['deviceCommand'] = obj
      }else{
        this.command['deviceCommand'] = item
      }
      if (item.contentType == "commandData") {
        this.showCommand = true;
      } else {
        this.commandSend(this.command)
      }
    },

    async commandSend(command) {
      const { data: res } = await Command.sendCommand(command);
      if (res.message == "指令发送成功") {
        this.$message({
          message: '指令发送成功！',
          type: 'success'
        })
      } else if (res.message == "无此设备的指令下发权限") {
        this.$message({
          message: '无此设备的指令下发权限',
          type: 'error'
        })
      }
    },

  },

}
</script>

<style lang="scss" scoped>
#app {
  display: flex;
}

.device-info {
  width: 100%;
  display: flex;
  position: absolute;
  top: 0;
  height: 40px;
  line-height: 40px;

  .device-status {
    font-size: 18px;
    font-weight: 600;
    color: #a2edf7;
  }

  .device-signal-value {
    font-size: 18px;
    font-weight: 600;
    color: #a2edf7;
  }
}

.content-card {
  scrollbar-width: none;
  box-sizing: border-box;
  width: 80%;
  height: 220px;
  display: flex;
  flex-wrap: wrap;
  overflow: scroll;

  .update-time {
    position: absolute;
    bottom: 0px;
    right: 10px;
    color: #a2edf7;
  }

  .content-card-item {
    box-sizing: border-box;

    background: rgba(3, 161, 182, 0.2);
    color: #a2edf7;
    border: none;
    margin: 7px;
    width: 23%;
    .boxes {
      display: flex;
      width: 100%;
      .img-box {
        width: 30%;
        height: 100px;
        user-select: none;

        img {
          width: 70px;
          margin: 10px 5px;
        }
      }

      .text-box {
        width: 70%;
        height: 100px;
        padding-left: 10px;

        .show-info {
          line-height: 70px;
          font-size: 18px;
        }
      }
    }
  }
  .update-time {
    position: absolute;
    bottom: 0px;
    right: 5px;

    color: #a2edf7;
  }
}

.operation {
  scrollbar-width: none;
  width: 25%;
  height: 220px;
  overflow: scroll;
  padding: 0 10px;
  box-sizing: border-box;

  .title1 {
    padding: 20px 0 10px 10px;
    font-size: 16px;
    font-weight: 600;
    color: rgb(50, 199, 204);
    letter-spacing: 2px;
  }
  .title1::before {
    content: "丨";
    font-size: 16px;
    font-weight: 600;
    color: rgb(50, 199, 204);
  }
}
</style>