<template>
  <!-- 水压 -->
  <div>
    <dv-border-box-7 id="main" ref="innerCircle" class="config" :color="['#2f576c', '#2f576c']"></dv-border-box-7>
  </div>
</template>

<script>

import * as echarts from 'echarts';
import 'echarts-liquidfill/dist/echarts-liquidfill.min.js';

export default {
  name: 'WaterPressure',
  props: ["data", "deviceInfo"],
  data() {
    return {

      // 当前值
      pressureValue: 0,
      arrPressureValue: [],
      // 低阈值
      lowThreshold: 0,
      // 高阈值
      highThreshold: 0,

      max: 0,
      min: 0,

    }

  },

  created() {
  },

  mounted() {
    setTimeout(() => {
      this.drawWaterBubble()
    }, 1000)
  },

  methods: {
    getData() {
      //   高阈值和低阈值
      for (let i = 0; i < this.deviceInfo.device.length; i++) {
        if (this.deviceInfo.device[i].proName == "低阈值") {
          this.lowThreshold = Number(this.deviceInfo.device[i].value)
          //   console.log('低阈值：' + this.lowThreshold);
        }
        if (this.deviceInfo.device[i].proName == "高阈值") {
          this.highThreshold = Number(this.deviceInfo.device[i].value)
          //   console.log('高阈值:' + this.highThreshold);
        }
      }
      this.min = 0;
      this.max = (this.highThreshold + 0.5)
      //   console.log(this.max, this.min);


      // 当前水位值
      for (let i = 0; i < this.data.length; i++) {
        if (this.data[i].name == "值") {
          this.pressureValue = this.data[i].value;
          // this.pressureValue = 1.7
          this.arrPressureValue.push(this.pressureValue / this.max)
          //   console.log(this.pressureValue);
        }
      }
    },
    drawWaterBubble() {
      this.getData()

      let _this = this;
      //   const myEcharts = echarts.init(this.$refs.innerCircle)
      const myEcharts = echarts.init(document.getElementById('main'))
      let options = {
        title: [
          {
            text: '水位',
            textStyle: {
              fontSize: '20',
              fontWeight: '600',
              color: '#8bcdda',
            },
          },
        ],

        series: [
          {
            min: _this.min,
            max: _this.max,

            type: 'liquidFill',
            outline: {
              show: true,
            },
            backgroundStyle: {
              color: 'rgba(41, 77, 153, 0.8)'
            },
            shape: 'circle',
            radius: '90%',
            itemStyle: {
              color: _this.pressureValue < _this.lowThreshold ? '#FDDD60' : _this.pressureValue > _this.highThreshold ? '#FF6E76' : '#24c8ed',
              opacity: 1
            },
            data: _this.arrPressureValue,
            label: {
              show: true,
              fontSize: '30',
            }
          }
        ]
      }
      myEcharts.setOption(options)
    },

  }

}
</script>

<style lang="scss" scoped>
.config {
  width: 100%;
  height: 220px;
  margin: 5px;
  padding-top: 25px;
  box-sizing: border-box;

  overflow: hidden;
}
.content {
  position: relative;
  .status {
    display: inline-block;
    width: 100px;
    position: absolute;
    top: 0;
    left: 0%;
  }
}
</style>