<template>
  <div id="app">
    <!-- getDeviceListByCiIdStructure -->

    <div>
      <el-input placeholder="输入关键字进行搜索..." v-model="filterText" style="margin: 20px 10px 5px 0; width: 100%">
      </el-input>
      <el-select v-model="deviceType" placeholder="请选择设备类型" style="width:100%" clearable @change="changeDeviceType">
        <el-option v-for="item in deviceTypeList" :label="item.name" :value="item.name">
        </el-option>
      </el-select>
    </div>
    <div class="device-title">公司设备列表</div>
    <dv-decoration-3 style="width:250px;height:10px;" />

    <div class="container">

      <el-tree lazy :load="loadNode" :check-strictly="true" :data="companyEquipmentList" :props="defaultProps" :default-expand-all="false" node-key="id" :filter-node-method="filterNode" :expand-on-click-node="false" @node-click="getPickCompant" ref="tree">
        <!-- 树形控件 插槽 显示设备状态 -->
        <!-- <span class="custom-tree-node" slot-scope="{ node, data }" style="width:100%">
          <span>{{ node.label }}</span>
          <span v-if="data.id.indexOf('device')!=-1" style="float:right;display:inline-block;">
            <i class="iconfont" style="font-size:18px" title="类型">&#xea2d;</i>
          </span>
        </span> -->
      </el-tree>

    </div>

  </div>
</template>
<!-- <span style="float:right" v-if="node.ids">
            <el-tag size="mini" type="success">正常</el-tag>
            <el-tag size="mini" type="warning">报警</el-tag>
            <el-tag size="mini" type="info">失联</el-tag>
          </span> -->
<script>
import DeviceInfoManagement from '@/api/manageApi/DeviceInfoManagement'
import DeviceTypeManagement from '@/api/manageApi/DeviceTypeManagement'
export default {
  name: 'GetDeviceListByCiIdStructure',
  data() {
    return {
      // 关键字检索
      filterText: '',
      //   companyList: 
      companyEquipmentList: [],
      // 公司树形结构绑定
      defaultProps: {
        label: "name", // 公司名
        children: "smallCompanyList", // 子公司节点
        isLeaf: 'leaf',
      },
      device: [],
      checkedDevice: '',
      deviceTypeList: [],//该账号下所有设备类型
      deviceType: "",

      ciId: '',
      typeId: null,


      useInput: true,

      nodeList: [],
      deviceTypes:[{
        name: '用传',
        value: 'YC',
      },{
        name: '水压',
        value: 'waterPressure',
      },{
        name: '水浸',
        value: 'waterLevel',
      },{
        name: '网关',
        value: 'videoGateway',
      },{
        name: '摄像头',
        value: 'video',
      },{
        name: '点型温感',
        value: 'temperatureDetector',
      },{
        name: '点型烟感',
        value: 'smokeDetector',
      },{
        name: '剩余电流互感器',
        value: 'residualCurrentTransformer',
      },{
        name: '手动报警按钮',
        value: 'ManualAlarmButton',
      },{
        name: '消火栓按钮',
        value: 'FireHydrant',
      },{
        name: '电信水压',
        value: 'DXwaterPressure',
      },{
        name: '电信液位仪',
        value: 'DXLiquidLevelMeter',
      },{
        name: '烟感',
        value: 'DXHMSmoke',
      }],
    }
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },

  created() {
    this.getDeviceTypeByUser()
  },

  methods: {

    loadNode(node, resolve) {
      this.ciId = node.data.id
      //如果展开第一级节点，从后台加载一级节点列表
      if (node.level == 0) {
        this.loadfirstnode(resolve);
      }
      //如果展开其他级节点，动态从后台加载下一级节点列表
      if (node.level >= 1) {
        this.loadchildnode(node, resolve);
      }
    },

    async loadfirstnode(resolve) {
      let params = {
        level: 0,
      };
      const { data: res } = await DeviceInfoManagement.getTopCiIds()
      console.log(res);
      this.companyEquipmentList = res.data.topList
      console.log(this.companyEquipmentList);
      return this.companyEquipmentList;
    },

    //加载节点的子节点集合
    async loadchildnode(node, resolve) {
      console.log(node.data);
      // console.log("超过二级的", node, node.level);
      let params = {
        id: node.key,
      };
      const { data: res } = await DeviceInfoManagement.getDeviceListByCiId(this.ciId, this.typeId)
      console.log(res);
      return resolve(res.data.list);
    },

    //通过账号查设备类型
    getDeviceTypeByUser() {
      DeviceTypeManagement.getDeviceTypeByUser().then(res => {
        this.deviceTypeList = res.data.data.list
        console.log(this.deviceTypeList);
      })
    },

    // //改变设备类型
    async changeDeviceType(item) {
      console.log(item);
      if (item == '') {
        this.typeId = null
      }
      for (const i in this.deviceTypeList) {
        if (this.deviceTypeList[i]["name"] == item) {
          this.typeId = this.deviceTypeList[i]["id"]
        }
      }
      console.log(this.typeId);
      this.loadfirstnode()



      //   const { data: res } = await DeviceInfoManagement.getDeviceListByCiId(this.ciId, this.typeId)
      //   console.log(res);
      //   this.companyEquipmentList = res.data.list
      //   console.log(this.companyEquipmentList);


      //   console.log(this.typeId);
      //   const { data: info } = await DeviceInfoManagement.getDeviceListByCiIdStructure(this.typeId)
      //   this.companyEquipmentList = info.data.list
      //   console.log(this.companyEquipmentList);
    },
    getPickCompant(value) {
      this.$emit('companyInfo', value)
      this.device = value;
      if (value.id.indexOf('device') != -1) {
        this.checkedDevice = value.name;
        this.$emit('checkedDeviceId', this.checkedDevice)
      }
    },

    // 选择公司过滤器
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
  }

}
</script>

<style lang="scss" scoped>
.container {
  scrollbar-width: none;
  box-sizing: border-box;
  width: 100%;
  height: 75vh;
  //   border:1px solid red;
  overflow: scroll;

  //   background: green;
}
.device-title {
  font-family: "宋体";
  font-weight: 600;
  margin: 5px 0;
  font-size: 20px;
  letter-spacing: 2px;
  color: #a2edf7;
}
.device-title::before {
  content: "丨";
}

// 树结构 ##################################################################
// /deep/.el-icon-caret-right:before {
//   color: #035e61;
// }
/deep/.el-tree-node__content {
  height: 30px;
  font-size: 15px;
  line-height: 30px;
}
.el-tree {
  color: #80ecf0;
  background: none;
}
/deep/.el-tree-node__content:hover {
  //   background: rgb(48, 79, 145);
  background: #fff;
  color: #035e61;
  font-weight: 600;
  border-radius: 3px;
}

// input 框
/deep/.el-input__inner {
  background: rgba(1, 79, 85, 0.3);
  color: #00ffff;
  border: none;
}
/deep/.el-range-input {
  background: none;
}
</style>