<template>
  <div id="app">
    <el-header class="header-bg bgclor" style="height: 80px;width:100%;position:absolute;top:0px">
      <show-header></show-header>
    </el-header>

    <div class="container">
      <!-- 公司 设备列表 -->
      <dv-border-box9 class="left" :color="['#2681a2','#2681a2']">
        <!-- 公司设备列表 -->
        <GetDeviceListByCiIdStructure @checkedDeviceId="checkedDeviceId" />
      </dv-border-box9>

      <!-- 设备信息 -->
      <dv-border-box-10 class="content" :color="['#2cd3c7','#2cd3c7']">
        <div v-if="noDeviceInfo" class="noDeviceInfo">
          <!-- <img src="../../assets/images/noinfo1.png"> -->
          <div>请选择设备...</div>
        </div>
        <!-- src\assets\images\noinfo.png -->

        <!-- tab栏 -->
        <div class="content-item" v-else>
          <el-tabs v-model="activeName">
            <!-- 基本信息 -->
            <div v-if="flag">
              <DeviceBaseInfo :deviceInfos="deviceInfo" />
            </div>
            <!-- 设备属性 -->
            <el-tab-pane v-for="item in tableBarList" :label="item.title" :name="item.name" :key="item.name">
              <div class="item-box" v-if="flag">
                <!-- 实时监控页面 -->
                <div v-if="item.name == 'first'">
                  <!-- 实时数据 -->
                  <OverviewInformation :deviceInfos="deviceInfo" />
                  <!-- 设备折线图数据 -->
                  <DataAnalysis :deviceInfos="deviceInfo" />
                </div>

                <!-- 故障预警 -->
                <div v-if="item.name == 'second'">
                  <AlarmHistoryData :deviceInfos="deviceInfo" />
                </div>

                <!-- 历史数据 -->
                <div v-if="item.name == 'third'">
                  <HistoricalData :deviceInfos="deviceInfo" />
                </div>
                <div v-if="item.name == 'fourth'">
                  <!-- <CommandHistory /> -->
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>

      </dv-border-box-10>

    </div>
  </div>
</template>

<script>
// ##### 组件
// 公司设备列表
import GetDeviceListByCiIdStructure from '@/components/ModuleManage/GetDeviceListByCiIdStructure'
// 标题
import ShowHeader from '@/components/deviceClass/ShowHeader'
// 基本信息
import DeviceBaseInfo from '@/components/ModuleManage/DeviceBaseInfo'
// 故障预警
import AlarmHistoryData from '@/components/ModuleManage/AlarmHistoryData'
// 历史数据
import HistoricalData from '@/components/ModuleManage/HistoricalData'
// 实时监控
import OverviewInformation from '@/components/ModuleManage/OverviewInformation'
// 操作记录
import CommandHistory from '@/views/manage/aSingleDeviceInformation/CommandHistory'
// 数据分析
import DataAnalysis from '@/components/ModuleManage/DataAnalysis';


// ##### 接口
import deviceInfoManagement from "@/api/manageApi/DeviceInfoManagement.js";

export default {
  name: 'DeviceDisplay',
  components: {
    ShowHeader, DeviceBaseInfo, AlarmHistoryData, HistoricalData, OverviewInformation, CommandHistory, DataAnalysis, GetDeviceListByCiIdStructure,
  },
  data() {
    return {
      flag: false,
      tableBarList: [{
        name: "first",
        title: "实时监控"
      }, {
        name: "second",
        title: "故障预警"
      }, {
        name: "third",
        title: "历史数据"
      }
        //   , {
        //     name: "fourth",
        //     title: "操作记录"
        //   }
      ],
      activeName: 'first',
      deviceId: '',
      // 设备信息列表
      deviceInfo: [],
      // 公司设备
      companyEquipmentList: [],
      noDeviceInfo: true,
      compId: '',
    }
  },



  created() {



    if (this.deviceId != '') {
      this.getDeviceInfoByDeviceId()
    } else if (this.$route.query.companyId) {
      // this.checkedDeviceId()
    //   console.log(this.$route.query.address == "show");
      this.deviceId = this.$route.query.companyId
      this.getDeviceInfoByDeviceId()

    }
  },



  methods: {

    async getDeviceInfoByDeviceId() {
      const { data: res } = await deviceInfoManagement.getDeviceInfoById(this.deviceId)
      if (res.message == "成功") {
        this.deviceInfo = res.data.deviceInfoVo
        this.flag = true;
      }
    },

    // 获取子组件中的设备id
    checkedDeviceId(device) {
      this.deviceId = '';
      this.deviceId = device
      this.getDeviceInfoByDeviceId()
      this.noDeviceInfo = false;
    }

  }

}
</script>

<style lang="scss" scoped>
.container {
  margin-top: 20px;
  width: 100%;
  display: flex;
  box-sizing: border-box;

  height: 90vh;

  .left {
    box-sizing: border-box;
    width: 20%;
    height: 90vh;
    // overflow: scroll;
    margin-right: 10px;
    padding: 0 20px;
  }
  .content {
    box-sizing: border-box;
    border-radius: 10px;
    width: 80%;
    height: 90vh;
    .content-item {
      padding: 10px;

      .item-box {
        box-sizing: border-box;
        width: 100%;

        height: 59vh;
      }
    }
  }
}
.noDeviceInfo {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  padding-top: 30%;
  margin: 0 auto;
  user-select: none;
  img {
    margin: 0 auto;
    filter: invert(30%);
    transform: rotateY(180deg);
  }
  div {
    font-family: "宋体";
    letter-spacing: 5px;
    font-size: 30px;
    text-align: center;
    color: #42afaa;
    font-weight: 600;
  }
}
/deep/.el-empty__description p {
  font-size: 25px;
}
// tab栏样式
/deep/.el-tabs__item {
  font-size: 22px;
  font-family: "宋体";
  font-weight: 600;
  color: #2ed8e4;
  letter-spacing: 2px;
}
/deep/.el-tabs__item:hover {
  color: rgb(163, 245, 169);
}
/deep/.el-tabs__item.is-active {
  color: rgb(4, 233, 233);
}
/deep/.el-tabs__active-bar {
  background: rgb(4, 233, 233);
}
/deep/.el-tabs__nav-wrap::after {
  background-color: #0b486a;
}
/deep/::-webkit-scrollbar {
  display: none;
}
// 树形图hover
/deep/.el-tree-node__content:hover{
  background-color:rgba($color:#007a91, $alpha: 0.5) !important;
  color: #72d4dc;
}
</style>