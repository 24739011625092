<template>
  <div id="app">
    <!-- dataAnalysis 数据分析折线图 -->
    <!-- <dv-border-box-2 class="container" :color="['cadetblue', 'cadetblue']">
    <dv-border-box-2 /> -->
    <dv-border-box-2 class="container" :color="['cadetblue', 'cadetblue']">
      <div class="title">数据分析</div>
      <dv-decoration-3 style="width:250px;height:10px;" />
      <div class="content">
        <dv-border-box-13 class="content-left" :color="['cadetblue', 'cadetblue']">
          <div class="subtitle">
            <div><i class="el-icon-menu map-title"></i> 设备属性列表</div>
          </div>
          <div class="content-left-btm">
            <div v-for="(item,index) in changedDeviceInfoList" :key="index" class="info-list">
              <div @click="switchChart(item)">
                <span style="color:#7dc5eb;font-weight:600">{{item.name}}</span>
              </div>
            </div>
          </div>
        </dv-border-box-13>

        <dv-border-box-12 class="content-right" :color="['cadetblue', 'cadetblue']">
          <LineChart v-if="isShowTu" :dateList="dateList" :valueList="value1List" :hValue="hValue" :lValue="lValue" :chartTitle="chartTitle" :unit="unit" />
          <div class="div-tips" v-if="showTips">暂无数据</div>
        </dv-border-box-12>
      </div>
    </dv-border-box-2>
  </div>
</template>

<script>
import deviceInfoManagement from "@/api/manageApi/DeviceInfoManagement.js";

import deviceInfo from "@/api/manageApi/DeviceInfoManagement";

import LineChart from "@/components/ModuleManage/LineChart";

export default {
  name: 'DataAnalysis',
  props: ['deviceInfos'],
  components: {
    LineChart,
  },
  data() {
    return {

      // 折线图要传的参数
      proList: [],//动态属性
      dateList: [], //折线图时间显示数组
      value1List: [], //折线图值显示数组
      hValue: "", //折线图高阈值显示
      lValue: "", //折线图低阈值显示
      chartTitle: "",//折线图标题
      unit: "",//折线图单位显示
      isShowTu: false,//是否显示折线图,
      showTips: true,//无折线图时的提示，
      showChartDeviceId: "",//显示折线图的设备id
      showChartDeviceTypeId: "",//显示折线图的设备类型id

      // 设备数据列表
      changedDeviceInfoList: [],
      deviceInfo: {},
    }
  },

  // 监听数据是否发生改变,如果改变,刷新数据
  watch: {
    deviceInfos(newVal, oldVal) {
      if (newVal.id != oldVal.id) {
        this.deviceInfo = newVal
        this.isShowTu = false;
        this.showTips = true;
        this.changedDeviceInfoList = [];
        this.getDeviceValue();
      }
    }
  },

  created() {
    this.deviceInfo = this.deviceInfos;
    if (this.deviceInfo.id != '') {
      this.getDeviceValue()
    }
  },

  methods: {
    // 查询数据信息
    async getDeviceValue() {
      const { data: res } = await deviceInfoManagement.getDeviceInfoByDeviceId(this.deviceInfo.id, 1, 1)
      this.deviceValueInfoList = res.data.deviceDynamicVo;
      this.deviceValueInfoList = []
      this.updateTime = res.data.deviceDynamicVo.lastDataTime
      let lastDataTime = res.data.deviceDynamicVo["lastDataTime"]
      let createDeviceTime = res.data.deviceDynamicVo["createDeviceTime"]
      let isOnline = true;
      if (lastDataTime == null || createDeviceTime > lastDataTime) {
        isOnline = false;
      }
      for (const i in res.data.deviceDynamicVo["nameList"]) {
        let obj = {}
        obj.id = res.data.deviceDynamicVo["nameList"][i]
        obj.value = res.data.deviceDynamicVo["valueList"][i]
        this.deviceValueInfoList.push(obj)
      }
      //其它类型设备走下面正常处理
      let typeMap = new Map([
        ["井盖", { name: [], value: [] }],
        ["压力", { name: [], value: [] }],
        ["电池", { name: [], value: [] }],
        ["水位", { name: [], value: [] }],
      ]);
      let typeList = ["井盖", "压力", "电池", "水位"]
      this.showDeviceInfoList = []//清空数据
      let deviceStatus = ""
      let nameList = []
      let valueList = []
      let obj = {}
      for (const i in this.deviceValueInfoList) {
        nameList = []
        valueList = []
        if (typeMap.has(this.deviceValueInfoList[i]["id"].substring(0, 2))) {
          typeMap.get(this.deviceValueInfoList[i]["id"].substring(0, 2))["name"].push(this.deviceValueInfoList[i]["id"])
          typeMap.get(this.deviceValueInfoList[i]["id"].substring(0, 2))["value"].push(this.deviceValueInfoList[i]["value"])
        } else if (this.deviceValueInfoList[i]["id"].indexOf("状态") != -1
          && this.deviceValueInfoList[i]["value"] != ""
          && ((this.deviceValueInfoList[i]["value"] == "正常" && deviceStatus == "") || (this.deviceValueInfoList[i]["value"] != "正常"))
          && this.deviceValueInfoList[i]["value"] != null
          && this.deviceValueInfoList[i]["value"] != undefined
        ) {
          deviceStatus = this.deviceValueInfoList[i]["value"]
        } else if ((this.deviceValueInfoList[i]["id"].indexOf("值") != -1
          || this.deviceValueInfoList[i]["id"].indexOf("压力") != -1
          || this.deviceValueInfoList[i]["id"].indexOf("电流") != -1
          || this.deviceValueInfoList[i]["id"].indexOf("电压") != -1
          || this.deviceValueInfoList[i]["id"].indexOf("角度") != -1
          || this.deviceValueInfoList[i]["id"].indexOf("电量") != -1
          || this.deviceValueInfoList[i]["id"].indexOf("功率") != -1
          || this.deviceValueInfoList[i]["id"].indexOf("用水") != -1
          || this.deviceValueInfoList[i]["id"].indexOf("用电") != -1
          )
          && this.deviceValueInfoList[i]["id"].indexOf("状态") == -1
          && this.deviceValueInfoList[i]["id"].indexOf("记录") == -1
          && this.deviceValueInfoList[i]["id"].indexOf("时间") == -1) {
          nameList.push(this.deviceValueInfoList[i]["id"])
          if (this.deviceValueInfoList[i]["value"] == ""
            || this.deviceValueInfoList[i]["value"] == null
            || this.deviceValueInfoList[i]["value"] == undefined) {
            valueList.push("暂无")
          } else {
            valueList.push(this.deviceValueInfoList[i]["value"])
          }
          obj["nameList"] = nameList
          obj["valueList"] = valueList
          this.showDeviceInfoList.push(JSON.parse(JSON.stringify(obj)))
        }
      }
      nameList = []
      valueList = []
      let isLost = false;//判断电信设备是否离线等
      if (this.deviceInfo.typeId.indexOf("DX") != -1) {
        const { data: ress } = await deviceInfoManagement.queryAEPDeviceStatus(this.deviceInfo.typeId, this.deviceInfo.id)
        // console.log(ress)
        if (ress.data.status == "离线") {
          nameList.push("设备状态")
          valueList.push("离线")
          isLost = true;
        } else if (ress.data.status == "已注册") {
          nameList.push("设备状态")
          valueList.push("未激活")
          isLost = true;
        } else if (ress.data.status == "已注销") {
          nameList.push("设备状态")
          valueList.push("已注销")
          isLost = true;
        }
      }
      if (isLost == false) {
        if (deviceStatus != "" && isOnline == true) {
          nameList.push("设备状态")
          valueList.push(deviceStatus)
        } else if (deviceStatus == "" && isOnline == true) {
          nameList.push("设备状态")
          valueList.push("正常")
        } else {
          if(this.deviceInfo.id.indexOf("IOE")!=-1
          ||this.deviceInfo.id.indexOf("_")!=-1
          ||this.deviceInfo.typeId == "DXHippoAcoustoOptic"
          ||this.deviceInfo.typeId == "numPressureGauge"){
              if(deviceStatus != ""){
                nameList.push("设备状态")
                valueList.push(deviceStatus)
              }else{
                nameList.push("设备状态")
                valueList.push("正常")
              }
            }else{
              nameList.push("设备状态")
              valueList.push("未服务")
              isLost = true
            }
        }
      }
      // console.log(nameList)
      // console.log(valueList)
      obj["nameList"] = nameList
      obj["valueList"] = valueList
      this.showDeviceInfoList.push(JSON.parse(JSON.stringify(obj)))
      for (const i in typeList) {
        obj = {}
        if (typeMap.get(typeList[i]).name.length != 0) {
          let status = ""
          nameList = []
          valueList = []
          for (const ii in typeMap.get(typeList[i]).name) {
            if (typeMap.get(typeList[i]).name[ii].indexOf("状态") != -1
              && typeMap.get(typeList[i]).value[ii] != ""
              && ((typeMap.get(typeList[i]).value[ii] == "正常" && status == "") || (typeMap.get(typeList[i]).value[ii] != "正常"))
              && typeMap.get(typeList[i]).value[ii] != null
              && typeMap.get(typeList[i]).value[ii] != undefined) {
              status = typeMap.get(typeList[i]).value[ii]
            } else if ((typeMap.get(typeList[i]).name[ii].indexOf("值") != -1
              || typeMap.get(typeList[i]).name[ii].indexOf("压力") != -1
              || typeMap.get(typeList[i]).name[ii].indexOf("电流") != -1
              || typeMap.get(typeList[i]).name[ii].indexOf("电压") != -1
              || typeMap.get(typeList[i]).name[ii].indexOf("角度") != -1
              || typeMap.get(typeList[i]).name[ii].indexOf("电量") != -1)
              && typeMap.get(typeList[i]).name[ii].indexOf("状态") == -1
              && typeMap.get(typeList[i]).name[ii].indexOf("记录") == -1
              && typeMap.get(typeList[i]).name[ii].indexOf("时间") == -1) {
              nameList.push(typeMap.get(typeList[i]).name[ii])
              if (typeMap.get(typeList[i]).value[ii] == ""
                || typeMap.get(typeList[i]).value[ii] == null
                || typeMap.get(typeList[i]).value[ii] == undefined) {
                valueList.push("暂无")
              } else {
                valueList.push(typeMap.get(typeList[i]).value[ii])
              }
            }
          }
          // if (status != "") {
          //   nameList.push("状态")
          //   valueList.push(status)
          // } else {
          //   // nameList.push("状态")
          //   // valueList.push("未服务")
          // }
          // console.log(nameList)
          // console.log(valueList)
          obj["nameList"] = nameList
          obj["valueList"] = valueList
          this.showDeviceInfoList.push(JSON.parse(JSON.stringify(obj)))
        }
      }
      for (let i = 0; i < this.showDeviceInfoList.length; i++) {
        for (let j = 0; j < this.showDeviceInfoList[i].nameList.length; j++) {
          let obj = {};
          obj.name = this.showDeviceInfoList[i].nameList[j]
          obj.value = this.showDeviceInfoList[i].valueList[j]
          this.changedDeviceInfoList.push(obj)
        }
      }
      if (isLost) {
        let length = this.changedDeviceInfoList.length
        for (let i = 0; i < length; i++) {
          if (this.changedDeviceInfoList[i].name.indexOf("状态") == -1) {
            this.changedDeviceInfoList.splice(i, 1)
            i--
          }
        }
      }
      // 移除设备状态
      // for (let i = 0; i < this.changedDeviceInfoList.length; i++) {
      //   if (this.changedDeviceInfoList[i].name == "设备状态") {
      //     this.changedDeviceInfoList.splice(i, 1);
      //     console.log(this.utilityClass);
      //     break;
      //   }
      // }
      //   console.log(this.changedDeviceInfoList);
      // console.log(this.deviceValueInfoList);
      // let arr = []
      // for (let i = 0; i < this.deviceValueInfoList.nameList.length; i++) {
      //   let obj = {}
      //   obj.name = this.deviceValueInfoList.nameList[i]
      //   obj.value = this.deviceValueInfoList.valueList[i]
      //   arr.push(obj)
      // }
      // this.deviceValueInfoList = arr
      // console.log(this.deviceValueInfoList);
      //   }
    },

    // 设备属性列表事件
    switchChart(item) {
      if (item.name) {
        if (item.name.indexOf("状态") != -1) {
          this.$message.warning("无设备状态数据！")
          this.isShowTu = false
          this.showTips = true
        } else {
          item = item.name
          this.isShowTu = false;
          this.chartTitle = item
          //   deviceInfo.getDayValue(this.showCharDeviceId,"","",this.showChartDeviceTypeId,this.showCharDeviceId + ".value." + item).then((res) => {
          deviceInfo.getDayValue(this.deviceInfo.id, "", "", this.deviceInfo.typeId, this.deviceInfo.id + ".value." + item).then((res) => {
            if (res.data.message == "不显示") {
              this.isShowTu = false;
              this.showTips = true;
            } else {
              this.dateList = res.data.data.dateList;
              this.value1List = res.data.data.valueList;
              if (res.data.data.hValue != undefined && res.data.data.hValue != null) {
                this.hValue = res.data.data.hValue;
                this.lValue = res.data.data.lValue;
              }
              if (res.data.data.unit != "" && res.data.data.unit != undefined) {
                this.unit = res.data.data.unit
              }
              this.isShowTu = true
              this.showTips = false;
            }
          })
        }
      }
    },

    showCharts() {
      this.proList = [];
      this.chartTitle = "";
      this.isShowTu = false
      this.showTips = true;
      this.showCharDeviceId = this.deviceId
      this.showChartDeviceTypeId = this.deviceTypeId
      deviceInfo.getDynamicPro(this.deviceTypeId)
        .then((res) => {
          let _this = this;
          for (const i in res.data.data.list) {
            if ((res.data.data.list[i].indexOf("值") != -1
              || res.data.data.list[i].indexOf("电压") != -1
              || res.data.data.list[i].indexOf("电流") != -1
            )
              && res.data.data.list[i].indexOf("状态") == -1
              && res.data.data.list[i].indexOf("阈值") == -1) {
              this.proList.push(res.data.data.list[i]);
            }
          }
          if (this.proList.length == 1 || this.proList.length > 1) {
            deviceInfo.getDayValue(
              this.deviceId,
              "",
              "",
              this.deviceTypeId,
              this.deviceId + ".value." + this.proList[0]
            ).then((res) => {
              if (res.data.message == "不显示") {
                _this.isShowTu = false;
                this.showTips = true;
              } else {
                _this.dateList = res.data.data.dateList;
                _this.value1List = res.data.data.valueList;
                if (res.data.data.hValue != undefined && res.data.data.hValue != null) {
                  _this.hValue = res.data.data.hValue;
                  _this.lValue = res.data.data.lValue;
                }
                if (res.data.data.unit != "" && res.data.data.unit != undefined) {
                  this.unit = res.data.data.unit
                }
                this.chartTitle = this.proList[0]
                _this.isShowTu = true
                this.showTips = false;
              }
            })
          }
        })
    },
  }
}
</script>

<style lang='scss' scoped>
.container {
  font-family: "宋体";
  width: 100%;
  margin: 10px 0;
  height: 28vh;
  box-sizing: border-box;
  padding: 20px 10px;

  .title {
    font-size: 20px;
    color: #95dbe8;
    font-weight: 600;
  }
  .title::before {
    content: "丨";
    font-size: 20px;
    font-weight: 600;
    color: #95dbe8;
  }

  .content {
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 240px;
    display: flex;

    .content-left {
      width: 15%;
      box-sizing: border-box;
      padding: 10px 0 20px 0;

      .subtitle {
        font-size: 16px;
        padding: 10px;
        color: #95dbe8;
        font-weight: 600;
        background: rgba(0, 0, 0, 0.1);
      }

      .content-left-btm {
        scrollbar-width: none;
        overflow: scroll;
        height: 190px;

        .info-list {
          cursor: pointer;
          box-sizing: border-box;
          height: 50px;
          line-height: 50px;
          margin: 5px;
          padding-left: 15px;
          box-shadow: 0px 0px 4px rgb(99, 118, 160);
        }

        .info-list:hover {
          background: rgba(4, 188, 212, 0.3);
        }
      }
    }

    .content-right {
      margin-left: 10px;
      box-sizing: border-box;
      width: 84%;
      padding: 10px;
      //   border: 1px solid yellow;

      .div-tips {
        text-align: center;
        margin-top: 100px;
        font-size: 18px;
        letter-spacing: 3px;
        font-weight: 600;
        color: #2f93a1;
      }
    }

    // .image-box {
    //   img {
    //     width: 60px;
    //   }
    // }
    // .text-box {
    //   font-size: 16px;
    //   height: 55px;
    //   line-height: 55px;
    //   padding-left: 20px;

    //   // 实时数据值
    //   .value {
    //     color: #7dc5eb;
    //     font-weight: 600;
    //   }
    // }
  }
}
</style>