function deviceAttributeName(deviceInfo) {
    for (const item of deviceInfo) {
        if (item.name == '当前温度值') {
            item.name = '温度'
            item.value = item.value + '℃'
        }
        if (item.name == '当前湿度值') {
            item.name = '湿度'
            item.value = item.value + '%'
        }
        if (item.name == '电池电量') {
            // item.name = '电量'
            item.value = item.value + '%'
        }
        if (item.name == '压力值') {
            // item.name = '压力值'
            item.value = item.value + 'Pa'
        }
        if (item.name.indexOf('角度') != -1) {
            // item.name = '压力值'
            item.value = item.value + '°'
        }
        if (item.name.indexOf('浓度值') != -1) {
            // item.name = '浓度值'
            // item.value = item.value + 'mg/m3'
        }
        if (item.name.indexOf('电压') != -1) {
            item.value = item.value + 'V'
        }
        if (item.name.indexOf('电流') != -1) {
            item.value = item.value + 'A'
        }
        // if (item.name.indexOf('烟感值') != -1) {
        //     item.value = item.value + 'ml/m3'
        // }
    }
}

export default {
    deviceAttributeName,
}