<template>
  <div id="app">
    <!-- 历史数据 -->
    <div class="container">
      <div>
        <el-table :data="historyDataList" style="width: 100%" highlight-current-row>
          <el-table-column type="index" width="60" align="center">
          </el-table-column>
          <el-table-column prop="deviceId" label="设备号" align="center">
          </el-table-column>
          <el-table-column prop="typeName" label="设备类型" align="center">
          </el-table-column>
          <el-table-column prop="valueName" label="设备属性" align="center">
          </el-table-column>
          <el-table-column prop="value" label="属性值" align="center">
          </el-table-column>
          <el-table-column prop="createTime" label="记录时间" align="center">
          </el-table-column>
        </el-table>
        <div>
          <el-pagination :background="true" :current-page.sync="current" :total="total" layout="total, prev, pager, next, jumper" @current-change="pagination(current)">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

// 分页查当前设备历史记录
import DeviceHistoryValue from "@/api/manageApi/DeviceHistoryValue"


export default {
  name: 'HistoricalData',
  props: ['deviceInfos'],
  data() {
    return {

      historyDataList: [],
      // 页码
      limit: 5,
      current: 1,
      total: 0,

      handleStatus: '', // 处理状态

      deviceId: "", //设备号

      valueName: '',

      deviceInfo: {},


    }
  },

  watch: {
    deviceInfos(newVal, oldVal) {
      //   console.log(newVal);
      //   console.log(oldVal);
      if (newVal.id != oldVal.id) {
        this.deviceInfo = newVal
        this.getHistoryData();
      }
    }
  },

  created() {
    this.deviceInfo = this.deviceInfos
    this.getHistoryData()
  },

  methods: {

    getHistoryData() {
      // 历史记录
      DeviceHistoryValue.getAll(
        this.current,
        this.deviceInfo.typeName,
        this.deviceInfo.id,
        this.valueName
      ).then(res => {
        // console.log(res);
        this.historyDataList = res.data.data.list;
        this.total = res.data.data.total;
      })
      //   console.log(this.historyDataList);
      //   this.total = res.data.total;

    },
    pagination() {
      this.getHistoryData()
    },

  },

}
</script>

<style lang="scss" scoped>
.container {
  scrollbar-width: none;
  box-sizing: border-box;
  width: 100%;
  height: 58vh;
  overflow: scroll;
}

// el-table 表格 透明样式   ################################################
/deep/ .el-table,
/deep/ .el-table__expanded-cell {
  background-color: transparent;
}
/* 表格内背景颜色 */
/deep/ .el-table th,
/deep/ .el-table tr,
/deep/ .el-table td {
  background-color: transparent;
}
/deep/::-webkit-scrollbar {
  display: none;
}
/deep/ .el-table th.el-table__cell > .cell {
  font-size: 16px;
  font-weight: 600;
}
/deep/.el-table__body,
.el-table__footer,
.el-table__header {
  font-size: 15px;
}
/deep/.el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border: none;
}
/deep/ th.el-table__cell.is-leaf {
  border: none;
}
/deep/.el-table tr {
  font-size: 15px;
  color: #80ecf0;
}

/deep/.el-table__row:hover {
  color: #035e61;
}

/deep/.el-table::before {
  height: 0px;
}
/deep/.el-table__fixed::before {
  height: 0px;
}
/deep/.el-table__fixed-right::before {
  height: 0px;
}

/deep/.el-table__empty-text {
  margin-top: 80px;
  border: none;
  font-size: 16px;
  letter-spacing: 3px;
}

// 分页 #########################################################
/deep/.el-pagination {
  bottom: 0px;
  height: 40px;
}
/deep/.el-pagination__total {
  color: #fff;
  font-weight: 600;
}
/deep/.el-pagination__jump {
  color: #fff;
  font-weight: 600;
}
/deep/.el-pager li {
  background: rgba(1, 79, 85, 0.1);
  color: #fff;
}
// /deep/.btn-prev {
//   background: rgba(1, 79, 85, 0);
//   color: #fff;
// }
// /deep/.el-pagination .btn-next {
//   background: rgba(1, 79, 85, 0);
//   color: #fff;
// }
// /deep/.el-pagination button:disabled {
//   background: none;
//   color: #fff;
// }

/deep/.el-table__fixed-right-patch {
  background: none;
}
// /deep/.el-input__inner {
//   background: none;
//   border: none;
//   color: #fff;
// }

// 处理弹框     ##################################
/deep/.el-dialog {
  border-radius: 20px;
  // background: rgb(2, 68, 104);
  // background: linear-gradient(135deg,#65799b,#5e2563);
  // background: #243b55;
}
/deep/.el-dialog__title {
  color: #0ea1a7;
  font-weight: 600;
  font-size: 20px;
}
/deep/.el-dialog__title::before {
  content: "丨";
  font-size: 20px;
  font-weight: 600;
}
</style>