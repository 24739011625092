<template>
  <div id="app">
    <!-- 故障预警 -->
    <div class="container">
      <div>
        <el-table :data="alarmHistorylist" style="width: 100%" :max-height="580" highlight-current-row>
          <el-table-column fixed type="index" label="序号" width="60" align="center">
          </el-table-column>

          <el-table-column prop="note" label="安装位置" align="center">
          </el-table-column>

          <el-table-column label="报警类型" align="center">
            <template slot-scope="scope">
              <span :style="scope.row.value.indexOf('警')!=-1?'color:red;font-weight:600;font-size:16px;letter-spacing: 2px':
                                scope.row.value.indexOf('故障')!=-1?'color:orange;font-weight:600;font-size:16px;letter-spacing: 2px':
                                scope.row.value.indexOf('失联')!=-1?'color:#ccc;font-weight:600;font-size:16px;letter-spacing: 2px': 
                                                                    'color:#747d8c;font-weight:600;font-size:16px;letter-spacing: 2px'
                                ">{{scope.row.value}}</span>
            </template>
          </el-table-column>

          <el-table-column label="设备状态" align="center" width="240">
            <template slot-scope="scope">
              <el-tag size="small" effect="dark" :type="scope.row.handleStatus=='未处理'||scope.row.handleStatus=='恢复未处理'?'danger':'success'">{{scope.row.handleStatus}}</el-tag>
            </template>
          </el-table-column>

          <el-table-column prop="createTime" label="报警时间" align="center">
          </el-table-column>

          <el-table-column fixed="right" label="操作" align="center" width="280">
            <template slot-scope="scope">
              <el-button @click="toVideo(scope.row)" icon="el-icon-video-camera" size="small" type="warning">视频</el-button>
              <el-button @click="toProcess(scope.row)" icon="el-icon-magic-stick" size="small" type="success">处理</el-button>
              <el-button @click="toCRT(scope.row)" icon="el-icon-view" size="small" type="primary">CRT</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div>
          <el-pagination :background="true" :current-page.sync="current" :total="total" layout="total, prev, pager, next, jumper" @current-change="pagination(current)">
          </el-pagination>
        </div>
      </div>

      <el-dialog title="异常设备处理" :visible.sync="processDevice" width="35%" style="background:rgba(0,0,0,0.5)">
        <div>
          <el-descriptions :column="2" border>
            <el-descriptions-item label="设备号">{{processList.deviceId}}</el-descriptions-item>
            <el-descriptions-item label="设备类型">{{processList.typeName}}</el-descriptions-item>
            <el-descriptions-item label="设备位置">{{processList.note}}</el-descriptions-item>
            <el-descriptions-item label="设备状态">{{processList.value}}</el-descriptions-item>
            <el-descriptions-item label="报警时间">{{processList.createTime}}</el-descriptions-item>
            <el-descriptions-item label="处理状态">
              <el-tag size="small" :type="processList.handleStatus=='未处理'?'danger':'success'">{{processList.handleStatus}}</el-tag>
            </el-descriptions-item>
          </el-descriptions>
        </div>
        <div>
          <div class="process-type">
            请选择要处理的类型：
          </div>
          <div>
            <el-radio-group v-model="radio">
              <el-radio :label="1" border>真实</el-radio>
              <el-radio :label="2" border>误报</el-radio>
              <el-radio :label="3" border>检修</el-radio>
              <el-radio :label="4" border>测试</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div slot="footer">
          <el-button @click="processDevice = false">取 消</el-button>
          <el-button type="primary" @click="processDeviceOk">确 定</el-button>
        </div>
      </el-dialog>

    </div>
  </div>
</template>

<script>
// CRT位置信息
import crtDeviceCoordinate from "@/api/manageApi/CrtDeviceCoordinate";
// 查报警历史记录       处理异常设备
import DeviceHistoryStatus from "@/api/manageApi/DeviceHistoryStatus";

export default {
  name: 'AlarmHistoryData',
  props: ['deviceInfos'],
  data() {
    return {
      // 设备处理弹框
      processDevice: false,

      //   处理类型
      radio: '',

      dataList: '1',
      handleStatus: '', // 处理状态
      current: 1,
      typeName: this.deviceInfos.typeName,
      valueName: '',
      deviceId: '',

      limit: 5,
      total: 0,

      //   报警历史数据集合
      alarmHistorylist: [],

      //   处理弹框信息
      processList: [],

      // 设备处理
      deviceHistoryStatus: {},


    }
  },

  // 监听数据是否发生改变,如果改变,刷新数据
  watch: {
    deviceInfos(newVal, oldVal) {
      if (newVal.id != oldVal.id) {
        this.typeName = newVal.typeName;
        this.deviceId = newVal.id;
        this.getAlarmHistoryInfo();
      }
    }
  },

  created() {
    this.deviceId = this.deviceInfos.id
    this.getAlarmHistoryInfo();
  },

  methods: {
    getAlarmHistoryInfo() {
      let obj = {}
      obj.currentPage = this.current
      obj.handleStatus = this.handleStatus
      obj.typeName = this.typeName
      obj.deviceId = this.deviceId
      obj.valueName = this.valueName
      obj.value = ""
      obj.startTime = ""
      obj.endTime = ""
      // 报警历史记录
      DeviceHistoryStatus.getAll(obj).then(res => {
        this.total = res.data.data.total
        this.alarmHistorylist = res.data.data.list;
      })
    //   console.log(this.alarmHistorylist);

      // 获取系统名称
      //   const { data: system } = await noticeSysManagement.getNotifyBigSystemById()

      //   for (let i = 0; i < this.alarmHistorylist.length; i++) {
      //     for (let j = 0; j < system.data.list.length; j++) {
      //       if (this.alarmHistorylist[i].notifySystemId == system.data.list[j].id) {
      //         this.alarmHistorylist[i].notifySystemId = system.data.list[j].bigSystemName
      //       }
      //     }
      //   }
    },



    // 查看视频
    toVideo(row) {
      //   console.log(row);
      if (row.videoUrl) {
        window.location.href = row.videoUrl;
      } else {
        this.$message.warning("该设备没有绑定摄像头！")
      }
    },

    // 设备处理
    toProcess(row) {
      this.deviceHistoryStatus = row;
      this.processList = row;
      //   console.log(row);
      this.processDevice = true;

    },
    // 跳转CRT
    toCRT(row) {
      //   console.log(row);
      //   console.log(row.deviceId);
      crtDeviceCoordinate.getCoordinateByDeviceId(row.deviceId).then((res) => {
        // console.log(res);
        if (res.data.message != "失败") {
          this.$router.push({
            name: "CRTShow",
            params: {
              CRTId: res.data.data.data.crtPictureId,
              deviceId: res.data.data.data.deviceInfoId,
            },
          });
        } else {

          this.$message({
            type: "warning",
            message: "该设备尚未添加点位！",
          });
        }
      });

    },
    // 处理异常设备确认按钮
    processDeviceOk() {
      switch (this.radio) {
        case 1: this.radio = "真实";
          break;
        case 2: this.radio = "误报";
          break;
        case 3: this.radio = "检修";
          break;
        case 4: this.radio = "测试";
          break;
      }
      if (this.radio == '') {
        this.$message.warning("请选择处理类型！")
      } else if (this.deviceHistoryStatus.handleStatus.indexOf('已处理') != -1) {
        this.$message.warning("该设备已处理，请勿重复提交！")
      } else {
        this.deviceHistoryStatus.processType = this.radio
        let obj = {}
        obj.id = this.deviceHistoryStatus.id
        obj.deviceId = this.deviceHistoryStatus.deviceId
        if(this.deviceHistoryStatus.process!=null&&this.deviceHistoryStatus.processType!=""){
          obj.process = this.deviceHistoryStatus.process
        }
        obj.processType = this.deviceHistoryStatus.processType
        DeviceHistoryStatus.updateRecord(obj).then(res=>{
          if(res.data.message=="处理成功"){
            this.$message({
              message: "处理成功！",
              type: "success",
            });
            this.getAlarmHistoryInfo();
          }else{
            this.$message({
              message: "处理失败！",
              type: "error",
            });
            this.getAlarmHistoryInfo();
          }
        })
        this.processDevice = false;
      }
    },
    pagination(current) {
      //   console.log(this.current);
      this.getAlarmHistoryInfo();
    }

  },

}
</script>

<style lang="scss" scoped>
.container {
  scrollbar-width: none;
  box-sizing: border-box;
  width: 100%;
  height: 58vh;
  overflow: scroll;
}

.process-type {
  font-size: 18px;
  margin: 10px 0;
  letter-spacing: 1px;
  color: #035e61;
  font-weight: 600;
  margin-top: 20px;
}

// el-table 表格 透明样式   ################################################
/deep/ .el-table,
/deep/ .el-table__expanded-cell {
  background-color: transparent;
}
/* 表格内背景颜色 */
/deep/ .el-table th,
/deep/ .el-table tr,
/deep/ .el-table td {
  background-color: transparent;
}
/deep/::-webkit-scrollbar {
  display: none;
}
/deep/ .el-table th.el-table__cell > .cell {
  font-size: 16px;
  font-weight: 600;
}
/deep/.el-table__body,
.el-table__footer,
.el-table__header {
  font-size: 15px;
}
/deep/.el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border: none;
}
/deep/ th.el-table__cell.is-leaf {
  border: none;
}
/deep/.el-table tr {
  font-size: 15px;
  color: #80ecf0;
}

/deep/.el-table__row:hover {
  color: #035e61;
}

/deep/.el-table::before {
  height: 0px;
}
/deep/.el-table__fixed::before {
  height: 0px;
}
/deep/.el-table__fixed-right::before {
  height: 0px;
}

/deep/.el-table__empty-text {
  margin-top: 80px;
  border: none;
  font-size: 16px;
  letter-spacing: 3px;
}

// 分页 #########################################################
/deep/.el-pagination {
  bottom: 0px;
  height: 40px;
}
/deep/.el-pagination__total {
  color: #fff;
  font-weight: 600;
}
/deep/.el-pagination__jump {
  color: #fff;
  font-weight: 600;
}
// /deep/.el-pager li {
//   background: rgba(1, 79, 85, 0.1);
//   color: #fff;
// }
// /deep/.btn-prev {
//   background: rgba(1, 79, 85, 0);
//   color: #fff;
// }
// /deep/.el-pagination .btn-next {
//   background: rgba(1, 79, 85, 0);
//   color: #fff;
// }
// /deep/.el-pagination button:disabled {
//   background: none;
//   color: #fff;
// }

/deep/.el-table__fixed-right-patch {
  background: none;
}
// /deep/.el-input__inner {
//   background: none;
//   border: none;
//   color: #fff;
// }

// 处理弹框     ##################################
/deep/.el-dialog {
  border-radius: 10px;
  // background: rgb(2, 68, 104);
  // background: linear-gradient(135deg,#65799b,#5e2563);
  // background: #243b55;
}
/deep/.el-dialog__title {
  color: #0ea1a7;
  font-weight: 600;
  font-size: 20px;
}
/deep/.el-dialog__title::before {
  content: "丨";
  font-size: 20px;
  font-weight: 600;
}
</style>