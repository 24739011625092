<template>
  <div id="map">
    <!-- :mapStyle="mapStyle"  -->
    <baidu-map v-if="show" class="map" :zoom="zoom" :center="mark" :scroll-wheel-zoom="true">
      <bm-marker :position="mark" :dragging="false" animation="BMAP_ANIMATION_BOUNCE"></bm-marker>
      <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT" type="BMAP_NAVIGATION_CONTROL_LARGE" :offset="{width: 40,height:60}"></bm-navigation>
      <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT" type="BMAP_NAVIGATION_CONTROL_PAN" :offset="{width: 65,height:10}"></bm-navigation>

      <!-- <bm-walking :start="startAdd" :end="endAdd" :auto-viewport="true" location="北京"></bm-walking> -->
      <bm-overview-map anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :isOpen="true"></bm-overview-map>
      <!-- 路线 -->
      <bm-driving :start="startAdd" :end="endAdd" :auto-viewport="true"></bm-driving>

      <!-- 区域划分 -->
      <bm-boundary :name="area" :strokeWeight="2" strokeColor="blue"></bm-boundary>

    </baidu-map>

    <dv-border-box-10 class="address" :color="['rgba(0,0,0,0.3)', 'rgba(0,0,0,0.3)']" backgroundColor="rgba(0,0,0,0.3)">
      <div class="title">路线规划</div>
      <el-input style="width:48.5%;margin-right:10px" type="text" v-model="startAdd" placeholder="输入起始位置"></el-input>
      <el-input style="width:48.5%" type="text" v-model="endAdd" placeholder="输入结束位置"></el-input>
      <br>
      <div class="title">区域划分</div>
      <el-input style="width:48.5%" type="text" v-model="area" placeholder="输入区域名称"></el-input>

      <i class="iconfont icon-iotechdangqian pos-a" title="点击回到设备位置" @click="toDevice"></i>
    </dv-border-box-10>

  </div>
</template>
<script>

import { BmlMarkerClusterer } from "vue-baidu-map";

export default {
  props: ["position"],
  components: {
    BmlMarkerClusterer,
  },
  data() {
    return {
      zoom: 15,

      mark: { lng: '', lat: '' },
      area: '',
      //  起始位置
      startAdd: '',
      // 结束位置
      endAdd: '',
      // 百度大厦
      // 北京邮电大学西门


      keyword: '',

      show: true,
    };
  },
  created() {
    this.mark.lng = this.position.lng;
    this.mark.lat = this.position.lat;
  },

  methods: {

    toDevice() {
      this.startAdd = ''
      this.endAdd = ''
      this.show = false
      setTimeout(() => {
        this.show = true
      }, 1)
    }

  },
};
</script>

<style lang="scss" scoped>
.map {
  width: 100vw;
  height: 100vh;
  user-select: none;
}
.address {
  user-select: none;
  position: absolute;
  top: 10px;
  left: 10px;

  width: 400px;
  height: 200px;
  z-index: 99;
  padding: 10px;
  box-sizing: border-box;

  .title {
    font-size: 20px;
    padding: 10px 0;
    font-weight: 600;
    color: rgb(21, 41, 38);
  }

  .pos-a {
    position: absolute;
    bottom: 7%;
    right: 5%;
    font-size: 35px;
    cursor: pointer;

    color: rgb(45, 248, 255);

    // 呼吸灯
    animation: breathing 0.8s linear infinite alternate;
  }
  //   left: 200px;
}
@keyframes breathing {
  0% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(1);
  }
}
/deep/.anchorBL {
  display: none;
}
</style>