<template>
  <div id="app">
    <!-- 实时监控 -->
    <dv-border-box-2 class="container" :color="['cadetblue', 'cadetblue']">
      <el-row :gutter="10">
        <el-col :span="6" class="title">实时数据</el-col>
      </el-row>
      <dv-decoration-3 style="width:250px;height:10px;" />

      <div class="content">
        <!-- 总线设备 -->
        <div v-if="deviceInfo.typeId== 'IntelligentFireControlTerminal'" class="content-card">
          <BusIot4GRepeater :deviceInfo="deviceInfo" />
        </div>

        <!-- 水压 / 电信水压 -->
        <div v-else-if="this.deviceInfo.typeId == 'waterPressure'||this.deviceInfo.typeId == 'DXwaterPressure'" class="shuiya">
          <div class="shuiya-left">
            <WaterPressure :data="changedDeviceInfoList" :deviceInfo="deviceInfo" />
          </div>
          <div class="shuiya-right">
            <dv-border-box-7 class="shuiya-2" :color="['#2f576c', '#2f576c']">
              <div class="shuiya-2-1">
                <div style="color:#a2edf7" v-for="(item,index) in changedDeviceInfoList">{{item.name }}：{{item.value}}</div>
              </div>
              <div class="description"><b>说明：</b> 压力值的最大值始终为当前设定的高阈值 + "<b>0.5</b>"</div>
              <div class="updateTime">
                数据更新时间：{{updateTime==""||updateTime==null||updateTime==undefined?'暂无':updateTime}}
              </div>
            </dv-border-box-7>
          </div>
        </div>

        <!-- 水位 / 电信水位 -->
        <div v-else-if="this.deviceInfo.typeId == 'waterLevel' || this.deviceInfo.typeId == 'DXwaterLevel'" class="shuiya">
          <div class="shuiya-left">
            <WaterLevel :data="changedDeviceInfoList" :deviceInfo="deviceInfo" />
          </div>
          <div class="shuiya-right">
            <dv-border-box-7 class="shuiya-2" :color="['#2f576c', '#2f576c']">
              <div class="shuiya-2-1">
                <div style="color:#a2edf7" v-for="(item,index) in changedDeviceInfoList">{{item.name }}：{{item.value}}</div>
              </div>
              <div class="updateTime">
                数据更新时间：{{updateTime==""||updateTime==null||updateTime==undefined?'暂无':updateTime}}
              </div>
            </dv-border-box-7>
          </div>
        </div>

        <div v-else class="content-card">
          <!-- 通用设备 -->
          <div class="content-card-box1">
            <el-card v-for="(item,index) in changedDeviceInfoList" :key="index" class="content-card-item">
              <div class="boxes">
                <div class="img-box">
                  <img :src="item.name.indexOf('设备')!=-1?iconList.shebei:
                       item.name.indexOf('井盖')!=-1?iconList.jingai:
                       item.name.indexOf('压力')!=-1?iconList.yali:
                       item.name.indexOf('功率')!=-1?iconList.gonglv:
                       item.name.indexOf('电压')!=-1?iconList.dianya:
                       item.name.indexOf('电流')!=-1?iconList.dianliu:
                       item.name.indexOf('烟感')!=-1?iconList.yangan:
                       item.name.indexOf('信号')!=-1?iconList.xinhao:
                       item.name.indexOf('湿度')!=-1?iconList.shidu:
                       item.name.indexOf('温度')!=-1?iconList.wendu:

                       item.name.indexOf('电池')!=-1?iconList.dianchidianya:iconList.shebei
              ">
                </div>
                <div class="text-box" :title="item.name+' : '+item.value">
                  <div>
                    {{item.name}}：{{item.value}}
                  </div>
                </div>
              </div>
            </el-card>
            <div class="update-time">
              数据更新时间：{{updateTime==""||updateTime==null||updateTime==undefined?'暂无':updateTime}}
            </div>
          </div>

          <div class="content-card-box2">
            <div class="text">操作指令：</div>
            <div class="box" v-if="deviceCommands.length!=0">
              <div style="margin:5px 0;height:180px;overflow:scroll">
                <el-button style="margin:5px" type="success" v-for="item in deviceCommands" size="mini" @click="sendCommand(item)" :loading="isLoading">{{item.commandName}}</el-button>
              </div>
              <el-dialog :title="commandTitle" :visible.sync="showCommand" width="28%" style="background:rgba(0,0,0,0.5)" :close-on-click-modal="false">
                <!-- 设置联系人 -->
                <div v-if="deviceInfos.typeId == 'YDAKeyToCall'&&deviceCommand.commandName == '设置联系人'">
                  <el-input v-model="commandData" placeholder="请输入联系人电话" style="width:80%"></el-input>
                  <div style="float:right;line-height:40px">
                    <el-button icon="el-icon-plus" circle type="primary" size="mini" @click="plusCommand"></el-button>
                  </div>
                  <div style="margin-top:20px">
                    <div v-for="(item,index) in commandDataList" :key="index" style="height: 30px;line-height 30px;width:100%;font-size:15px" class="rows">
                      <span><b>联系人 {{index+1}}：</b></span>
                      <span>{{item}}</span>
                      <el-button icon="el-icon-minus" circle type="danger" size="mini" @click="minusCommand(index)" style="float:right"></el-button>
                    </div>
                  </div>
                </div>

                <!-- 设置定时任务 -->
                <div v-else-if="deviceInfos.typeId == 'YDAKeyToCall'&&deviceCommand.commandName == '设置定时任务'">
                  <div style="font-size:15px;margin-bottom:10px">
                    <span><b>定时任务：</b></span>
                    <span class="addtasks" @click="addTask">添加任务</span>
                  </div>
                  <div style="margin:10px 0">
                    <div>
                      <b>星期（week）</b>
                    </div>
                    <el-checkbox v-model="taskOfWeek" v-for="(item, index) in 7" :key="index" :label="'星期 '+item" size="mini" border style="margin:5px"></el-checkbox>
                  </div>
                  <div style="margin:10px 0">
                    <b>小时（hour）</b>
                    <el-input-number v-model="taskOfHour" :min="1" :max="24" label="小时" style="width:28%"></el-input-number>&nbsp; &nbsp;
                    <!-- <el-input v-model="taskOfHour" type="mumber" placeholder="小时" style="width:25%"></el-input> &nbsp; &nbsp; &nbsp; &nbsp; -->
                    <b>分钟（min）</b>
                    <el-input-number v-model="taskOfMin" :min="1" :max="60" label="分钟" style="width:28%"></el-input-number>
                    <!-- <el-input v-model="taskOfMin"  type="mumber" placeholder="分钟" style="width:25%"></el-input> -->
                  </div>
                  <div style="margin:10px 0">
                    <b>任务内容</b>
                    <el-input type="textarea" :rows="2" placeholder="请输入任务内容" v-model="taskContent">
                    </el-input>
                  </div>
                  <div style="margin:10px 0">
                    <b>是否启用</b>
                    <el-switch v-model="taskAble" active-text="启用" inactive-text="关闭" active-color="#13ce66" inactive-color="#ccc" style="float:right"></el-switch>
                  </div>
                  <hr>
                  <div>
                    <div><b>任务列表：</b></div>
                    <div>
                      <div v-for="(item, index) in showTaskList" :key="index" style="border-bottom:1px solid #ccc;margin-top:5px;width:100%;">
                        <div>
                          <span><b>星期（week）：</b></span>
                          <span style="display:inline-block;width:10%;color:red;cursor:pointer;float:right" @click="deleteTask(index)">删除</span>
                          <span v-for="(items, indexs) in item.week" :key="indexs" v-if="items!=0">星期 {{items==1?items = indexs+1:items}}, </span>
                        </div>
                        <span style="display:inline-block;width:50%;margin:5px 0"><b>小时（hour）：</b> {{item.hour}}</span>
                        <span style="display:inline-block;width:40%;margin:5px 0"><b>分钟（min）：</b> {{item.min}}</span>
                        <span style="display:inline-block;width:40%;margin:5px 0"><b>是否启用</b>： {{item.disable==1?'不启用':'启用'}}</span>
                        <div style="display:inline-block;width:100%;margin:5px 0"><b>定时任务</b>： {{item.text}}</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-else>
                  <el-input v-model="commandData" placeholder="请输入指令内容"></el-input>
                </div>

                <div slot="footer">
                  <el-button @click="showCommand = false">取 消</el-button>
                  <el-button type="primary" @click="submitCommand">确 定</el-button>
                </div>
              </el-dialog>
            </div>
            <div v-else class="box1">
              暂无指令可操作
            </div>
          </div>

        </div>
      </div>

    </dv-border-box-2>
  </div>
</template>

<script>
import Datas from '@/assets/config/Datas'

// 总线物联网4G中继器
import BusIot4GRepeater from '@/components/ModuleManage/BusIot4GRepeater'

import deviceInfoManagement from "@/api/manageApi/DeviceInfoManagement.js";
import Command from '@/api/mapBuidingApi/Command'
import { Base64 } from 'js-base64';

// 水压
import WaterPressure from '@/components/ModuleManage/WaterPressure'
// 水位
import WaterLevel from '@/components/ModuleManage/WaterLevel'
// 智慧用电设备数据处理方法
// import DataProcess from '@/components/ModuleManage/DataProcess.js'
// const func = require('@/components/ModuleManage/DataProcess.js')

export default {
  name: 'OverviewInformation',
  props: ["deviceInfos"],
  components: {
    BusIot4GRepeater, WaterPressure, WaterLevel,
  },

  data() {
    return {
      isLoading: false,

      deviceCommands: [],  // 设备指令
      deviceCommand: {},//设备指令信息
      commandTitle: '',
      commandData: '',
      commandDataList: [],

      showCommand: false,       // 有数据的指令下发
      command: {},


      // 设备数据列表
      changedDeviceInfoList: [],

      // 数据更新时间
      updateTime: '',

      // 折叠面板激活
      dataName: '1',
      listName: '1',

      // 心跳周期
      cycleValue: 50,

      //   信号值
      signalVal: '',

      // 设备状态
      //   deviceSta: '',

      // 动态属性集合
      deviceValueInfoList: [],

      // 智慧用电数据集合
      wisdomElectricity: [],

      // 折线图要传的参数
      proList: [],//动态属性
      dateList: [], //折线图时间显示数组
      value1List: [], //折线图值显示数组
      hValue: "", //折线图高阈值显示
      lValue: "", //折线图低阈值显示
      chartTitle: "",//折线图标题
      unit: "",//折线图单位显示
      isShowTu: false,//是否显示折线图,
      showTips: false,//无折线图时的提示，
      showChartDeviceId: "",//显示折线图的设备id
      showChartDeviceTypeId: "",//显示折线图的设备类型id
      // src\assets\img\icon1\dianchidianya.png
      iconList: {
        yangan: require("@/assets/img/icon1/yangan.png"),
        yali: require("@/assets/img/icon1/yali.png"),
        xinhao: require("@/assets/img/icon1/xinhao.png"),
        shebei: require("@/assets/img/icon1/shebei.png"),
        jingai: require("@/assets/img/icon1/jingai1.png"),
        gonglv: require("@/assets/img/icon1/gonglv.png"),
        dianya: require("@/assets/img/icon1/dianya.png"),
        dianliu: require("@/assets/img/icon1/dianliu.png"),
        dianchidianya: require("@/assets/img/icon1/dianchidianya.png"),
        wengan: require("@/assets/img/icon/wengan.png"),
        shoubao: require("@/assets/img/icon/shoubao.png"),
        shenguang: require("@/assets/img/icon/shenguang.png"),
        shuchu: require("@/assets/img/icon/shuchu.png"),
        shidu: require("@/assets/img/icon/shidu.png"),
        wendu: require("@/assets/img/icon/wendu.png"),

        default: require("@/assets/img/icon/default.png"),
      },

      deviceInfo: {},
      IntervalTime: null,//设备状态刷新定时器



      // 设置定时任务
      taskOfWeek: [], // 选择星期
      taskOfHour: '', // 选择小时
      taskOfMin: '', // 选择分钟
      // 添加的任务列表
      taskList: [],
      showTaskList: [],    // 显示定时任务
      taskContent: '', //任务内容
      taskAble: 0,  // 是否启用  0启用
    }
  },

  // 监听数据是否发生改变,如果改变,刷新数据
  watch: {
    deviceInfos(newVal, oldVal) {
      if (newVal.id != oldVal.id) {
        this.changedDeviceInfoList = [];
        this.deviceInfo = newVal
        console.log(this.deviceInfo);
        this.getDeviceValue()
        this.getDeviceCmd()
      }
    }
  },

  created() {
    console.log(this.deviceInfos);
  },


  mounted() {
    this.deviceInfo = this.deviceInfos;
    this.getDeviceValue()
    this.getDeviceCmd()
    this.IntervalTime = setInterval(() => {
      this.changeDeviceStatus()
    }, 5000);
  },

  destroyed() {
    clearInterval(this.IntervalTime);
  },

  methods: {
    // 根据设备类型 id 查设备指令
    async getDeviceCmd() {
      const { data: res } = await Command.getDeviceCommand(this.deviceInfos.typeId)
      this.deviceCommands = res.data.deviceCommands
    },

    // 有数据的指令下发
    submitCommand() {
      if (this.commandData == '') {
        this.$message.warning("指令内容不能为空！")
      } else if (typeof (this.commandData) == 'object' && this.commandData.length == 0) {
        this.$message.warning("指令内容不能为空！")
      } else {
        this.deviceCommand.paramValue = this.commandData
        this.command['deviceId'] = this.deviceInfos.id;
        this.command['deviceCommand'] = this.deviceCommand
        this.showCommand = false;
        this.commandSend(this.command)
        this.commandData = '';
      }
    },

    // 删除当前定时任务
    deleteTask(index) {
      this.commandData.splice(index, 1)
      this.showTaskList.splice(index, 1)
      // this.commandData.splice(index, 1)
    },

    // 添加定时任务
    addTask() {
      if (this.taskOfWeek.length == 0) {
        this.$message.warning("请选择星期！")
      } else if (this.taskContent == '') {
        this.$message.warning("任务内容不能为空！")
      } else {

        let arr = []
        let num = 0
        for (let i = 0; i < this.taskOfWeek.length; i++) {
          arr.push(this.taskOfWeek[i].split(' ').pop())
        }
        let week = '00000000'
        for (let i = 0; i < arr.length; i++) {
          if (arr[i] != 1) {
            week = week.substring(0, 8 - arr[i]) + '1' + week.substring(9 - arr[i])
          } else {
            week = week.substring(0, 8 - arr[i]) + '1'
          }
        }
        let obj = {}
        obj.week = parseInt(Number(week), 2)
        obj.hour = this.taskOfHour
        obj.min = this.taskOfMin
        obj.text = Base64.encode(this.taskContent)
        obj.disable = this.taskAble ? 0 : 1

        this.commandData.push(obj)
        this.settings(this.commandData)
        // 添加后清空
        this.taskOfWeek = []
        this.taskContent = ''
        this.taskOfHour = ''
        this.taskOfMin = ''
      }
    },

    // 添加回显
    settings(arr) {
      let list = JSON.stringify(arr)
      this.showTaskList = JSON.parse(list)
      // this.showTaskList = Object.assign(this.commandData)
      for (let i = 0; i < this.showTaskList.length; i++) {
        // week 转二进制
        this.showTaskList[i].week = this.showTaskList[i].week.toString(2)
        this.showTaskList[i].week = this.showTaskList[i].week.split('').reverse().join('')
        // base64解码
        this.showTaskList[i].text = Base64.decode(this.showTaskList[i].text)
      }
    },

    //   指令下发
    async sendCommand(item) {
      this.commandData = ''
      this.deviceCommand = item
      this.commandTitle = item.commandName;
      this.command['deviceId'] = this.deviceInfos.id;
      if (this.deviceInfos.typeId == "IntelligentFireControlTerminal" && typeof item == "string" && item.constructor == String) {
        let obj = {}
        obj.contentType = "commandNoData"
        obj.paramValue = item
        this.command['deviceCommand'] = obj
      } else {
        this.command['deviceCommand'] = item
      }
      if (item.contentType == "commandData") {
        if (this.deviceInfos.typeId == 'YDAKeyToCall' && item.commandName == "设置联系人") {
          this.isLoading = true
          let obj = {}
          obj.deviceId = this.deviceInfos.id
          obj.value = "emergency_contact"
          const { data: res } = await Command.sendCommand(obj);
          if (res.message == "成功") {
            this.isLoading = false
          }
          this.commandDataList = res.data.value.split(',')
        } else if (this.deviceInfos.typeId == 'YDAKeyToCall' && item.commandName == "设置定时任务") {
          this.isLoading = true
          let obj = {}
          obj.deviceId = this.deviceInfos.id
          obj.value = "timer_tasks"
          const { data: res } = await Command.sendCommand(obj);
          if (res.message == "成功") {
            this.isLoading = false
          }
          this.commandData = res.data.value
          this.settings(this.commandData)
        }
        this.showCommand = true;
      } else {
        this.commandSend(this.command)
      }
    },

    // 指令添加联系人
    plusCommand() {
      if (this.commandData == '') {
        this.$message.warning("指令内容不能为空！")
      } else {
        let reg = /^1[0-9]{10}$/
        if (!reg.test(this.commandData)) {
          this.$message.error('请输入正确手机号!');
          return;
        } else {
          this.commandDataList.push(this.commandData)
          this.commandData = ''
          this.commandData = this.commandDataList.toString()
        }
      }
    },

    // 指令删除联系人
    minusCommand(index) {
      this.commandDataList.splice(index, 1)
    },

    async commandSend(command) {
      if (command.deviceCommand.typeId == "YDAKeyToCall" && command.deviceCommand.paramName == "timer_tasks") {
        command.timer_tasks = this.commandData
      }
      const { data: res } = await Command.sendCommand(command);
      if (res.message == "指令发送成功") {
        this.$message({
          message: '指令发送成功！',
          type: 'success'
        })
      } else if (res.message == "无此设备的指令下发权限") {
        this.$message({
          message: '无此设备的指令下发权限',
          type: 'error'
        })
      } else {
        this.$message({
          message: res.message,
          type: 'error'
        })
      }
    },

    // #############################################################################################################




    //改变设备的状态
    async changeDeviceStatus() {
      //如果是智慧用电相关设备，对数据单独处理
      if (this.deviceInfo.typeId == "smartElectricity") {

      } else if (this.deviceInfo.typeId == "IntelligentFireControlTerminal") {// 《总线物联网4G中继器》设备数据处理


      } else {
        const { data: res } = await deviceInfoManagement.getDeviceInfoByDeviceId(this.deviceInfo.id, 1, 1)
        this.deviceValueInfoList = []
        let lastDataTime = res.data.deviceDynamicVo["lastDataTime"]
        let createDeviceTime = res.data.deviceDynamicVo["createDeviceTime"]
        let isOnline = true;
        if (lastDataTime == null || createDeviceTime > lastDataTime) {
          isOnline = false;
        }
        for (const i in res.data.deviceDynamicVo["nameList"]) {
          let obj = {}
          obj.id = res.data.deviceDynamicVo["nameList"][i]
          obj.value = res.data.deviceDynamicVo["valueList"][i]
          this.deviceValueInfoList.push(obj)
        }
        //其它类型设备走下面正常处理
        let deviceStatus = ""
        for (const i in this.deviceValueInfoList) {
          if ((this.deviceValueInfoList[i]["id"].indexOf("状态") != -1
            || this.deviceValueInfoList[i]["id"] == "报警类型")
            && this.deviceValueInfoList[i]["value"] != ""
            && ((this.deviceValueInfoList[i]["value"] == "正常" && deviceStatus == "") || (this.deviceValueInfoList[i]["value"] != "正常"))
            && this.deviceValueInfoList[i]["value"] != null
            && this.deviceValueInfoList[i]["value"] != undefined
          ) {
            deviceStatus = this.deviceValueInfoList[i]["value"]
          }
        }
        let isLost = false;//判断电信设备是否离线等
        if (this.deviceInfo.typeId.indexOf("DX") != -1) {
          const { data: ress } = await deviceInfoManagement.queryAEPDeviceStatus(this.deviceInfo.typeId, this.deviceInfo.id)
          if (ress.data.status == "离线") {
            deviceStatus = "离线"
            isLost = true;
          } else if (ress.data.status == "已注册") {
            deviceStatus = "未激活"
            isLost = true;
          } else if (ress.data.status == "已注销") {
            deviceStatus = "已注销"
            isLost = true;
          }
        }
        if (isLost == false) {
          if (deviceStatus != "" && isOnline == true) {
          } else if (deviceStatus == "" && isOnline == true) {
            deviceStatus = "正常"
          } else {
            if (this.deviceInfo.id.indexOf("IOE") != -1
              || this.deviceInfo.id.indexOf("_") != -1
              || this.deviceInfo.typeId == "DXHippoAcoustoOptic"
              || this.deviceInfo.typeId == "numPressureGauge") {
              if (deviceStatus == "") {
                deviceStatus = "正常"
              }
            } else {
              deviceStatus = "未服务"
              isLost = true
            }
          }
        }
        for (const i in this.changedDeviceInfoList) {
          if (this.changedDeviceInfoList[i].name.indexOf("状态") != -1) {
            this.changedDeviceInfoList[i].value = deviceStatus
          }
        }
      }
    },
    // 查询数据信息
    async getDeviceValue() {
      //   console.log(this.deviceInfo);
      //   //智慧用电相关设备信息
      //   if (this.deviceInfo.typeId == "smartElectricity") {
      //     const { data: res } = await deviceInfoManagement.getDeviceInfoValueByDeviceId(this.deviceInfo.id)
      //     this.deviceValueInfoList = res.data.list;
      //     console.log(this.deviceValueInfoList);

      //     this.wisdomElectricityInfo()

      //     // 《总线物联网4G中继器》设备数据处理
      //   } else if (this.deviceInfo.typeId == "IntelligentFireControlTerminal") {
      //     const { data: res } = await deviceInfoManagement.getDeviceInfoValueByDeviceId(this.deviceInfo.id)
      //     this.deviceValueInfoList = res.data.list;
      //     console.log(this.deviceValueInfoList);

      //     this.busEquipmentMethod();

      //   } else {
      // 其它设备通用

      this.deviceValueInfoList = [];
      const { data: res } = await deviceInfoManagement.getDeviceInfoByDeviceId(this.deviceInfo.id, 1, 1)
      this.deviceValueInfoList = []
      this.updateTime = res.data.deviceDynamicVo.lastDataTime
      let lastDataTime = res.data.deviceDynamicVo["lastDataTime"]
      let createDeviceTime = res.data.deviceDynamicVo["createDeviceTime"]
      let isOnline = true;
      if (lastDataTime == null || createDeviceTime > lastDataTime) {
        isOnline = false;
      }
      for (const i in res.data.deviceDynamicVo["nameList"]) {
        let obj = {}
        obj.id = res.data.deviceDynamicVo["nameList"][i]
        obj.value = res.data.deviceDynamicVo["valueList"][i]
        this.deviceValueInfoList.push(obj)
      }
      //其它类型设备走下面正常处理
      let typeMap = new Map([
        ["井盖", { name: [], value: [] }],
        ["压力", { name: [], value: [] }],
        ["电池", { name: [], value: [] }],
        ["水位", { name: [], value: [] }],
      ]);
      let typeList = ["井盖", "压力", "电池", "水位"]
      this.showDeviceInfoList = []//清空数据
      let deviceStatus = ""
      let nameList = []
      let valueList = []
      let obj = {}
      for (const i in this.deviceValueInfoList) {
        nameList = []
        valueList = []
        if (typeMap.has(this.deviceValueInfoList[i]["id"].substring(0, 2))) {
          typeMap.get(this.deviceValueInfoList[i]["id"].substring(0, 2))["name"].push(this.deviceValueInfoList[i]["id"])
          typeMap.get(this.deviceValueInfoList[i]["id"].substring(0, 2))["value"].push(this.deviceValueInfoList[i]["value"])
        } else if ((this.deviceValueInfoList[i]["id"].indexOf("状态") != -1
          || this.deviceValueInfoList[i]["id"] == "报警类型")
          && this.deviceValueInfoList[i]["value"] != ""
          && ((this.deviceValueInfoList[i]["value"] == "正常" && deviceStatus == "") || (this.deviceValueInfoList[i]["value"] != "正常"))
          && this.deviceValueInfoList[i]["value"] != null
          && this.deviceValueInfoList[i]["value"] != undefined
        ) {
          if ((this.deviceValueInfoList[i]["value"].indexOf("正常") != -1
            && this.deviceValueInfoList[i]["value"].indexOf("故障") == -1
            && this.deviceValueInfoList[i]["value"].indexOf("警") == -1)
            || this.deviceValueInfoList[i]["value"].indexOf("无") != -1
            || this.deviceValueInfoList[i]["value"].indexOf("解除") != -1) {
            deviceStatus = "正常"
          } else {
            deviceStatus = this.deviceValueInfoList[i]["value"]
          }
        } else if ((this.deviceValueInfoList[i]["id"].indexOf("值") != -1
          || this.deviceValueInfoList[i]["id"].indexOf("压力") != -1
          || this.deviceValueInfoList[i]["id"].indexOf("电流") != -1
          || this.deviceValueInfoList[i]["id"].indexOf("电压") != -1
          || this.deviceValueInfoList[i]["id"].indexOf("角度") != -1
          || this.deviceValueInfoList[i]["id"].indexOf("电量") != -1
          || this.deviceValueInfoList[i]["id"].indexOf("功率") != -1
          || this.deviceValueInfoList[i]["id"].indexOf("用水") != -1
          || this.deviceValueInfoList[i]["id"].indexOf("用电") != -1
        )
          && this.deviceValueInfoList[i]["id"].indexOf("状态") == -1
          && this.deviceValueInfoList[i]["id"].indexOf("记录") == -1
          && this.deviceValueInfoList[i]["id"].indexOf("时间") == -1) {
          nameList.push(this.deviceValueInfoList[i]["id"])
          if (this.deviceValueInfoList[i]["value"] == ""
            || this.deviceValueInfoList[i]["value"] == null
            || this.deviceValueInfoList[i]["value"] == undefined) {
            valueList.push("暂无")
          } else {
            valueList.push(this.deviceValueInfoList[i]["value"])
          }
          obj["nameList"] = nameList
          obj["valueList"] = valueList
          this.showDeviceInfoList.push(JSON.parse(JSON.stringify(obj)))
        }
      }
      nameList = []
      valueList = []
      let isLost = false;//判断电信设备是否离线等
      if (this.deviceInfo.typeId.indexOf("DX") != -1) {
        const { data: ress } = await deviceInfoManagement.queryAEPDeviceStatus(this.deviceInfo.typeId, this.deviceInfo.id)
        if (ress.data.status == "离线") {
          nameList.push("设备状态")
          valueList.push("离线")
          isLost = true;
        } else if (ress.data.status == "已注册") {
          nameList.push("设备状态")
          valueList.push("未激活")
          isLost = true;
        } else if (ress.data.status == "已注销") {
          nameList.push("设备状态")
          valueList.push("已注销")
          isLost = true;
        }
      }
      if (isLost == false) {
        if (deviceStatus != "" && isOnline == true) {
          nameList.push("设备状态")
          valueList.push(deviceStatus)
        } else if (deviceStatus == "" && isOnline == true) {
          nameList.push("设备状态")
          valueList.push("正常")
        } else {
          if (this.deviceInfo.id.indexOf("IOE") != -1
            || this.deviceInfo.id.indexOf("_") != -1
            || this.deviceInfo.typeId == "DXHippoAcoustoOptic"
            || this.deviceInfo.typeId == "numPressureGauge") {
            if (deviceStatus != "") {
              nameList.push("设备状态")
              valueList.push(deviceStatus)
            } else {
              nameList.push("设备状态")
              valueList.push("正常")
            }
          } else {
            nameList.push("设备状态")
            valueList.push("未服务")
            isLost = true
          }
        }
      }
      obj["nameList"] = nameList
      obj["valueList"] = valueList
      this.showDeviceInfoList.push(JSON.parse(JSON.stringify(obj)))
      let mapStatus = "";//用于特殊类型状态显示
      for (const i in typeList) {
        obj = {}
        if (typeMap.get(typeList[i]).name.length != 0) {
          let status = ""
          nameList = []
          valueList = []
          for (const ii in typeMap.get(typeList[i]).name) {
            if (typeMap.get(typeList[i]).name[ii].indexOf("状态") != -1
              && typeMap.get(typeList[i]).value[ii] != ""
              && ((typeMap.get(typeList[i]).value[ii] == "正常" && status == "") || (typeMap.get(typeList[i]).value[ii] != "正常"))
              && typeMap.get(typeList[i]).value[ii] != null
              && typeMap.get(typeList[i]).value[ii] != undefined) {
              status = typeMap.get(typeList[i]).value[ii]
              if (status != null && status != "" && status != "正常") {
                mapStatus = status;
              }
            } else if ((typeMap.get(typeList[i]).name[ii].indexOf("值") != -1
              || typeMap.get(typeList[i]).name[ii].indexOf("压力") != -1
              || typeMap.get(typeList[i]).name[ii].indexOf("电流") != -1
              || typeMap.get(typeList[i]).name[ii].indexOf("电压") != -1
              || typeMap.get(typeList[i]).name[ii].indexOf("角度") != -1
              || typeMap.get(typeList[i]).name[ii].indexOf("电量") != -1)
              && typeMap.get(typeList[i]).name[ii].indexOf("状态") == -1
              && typeMap.get(typeList[i]).name[ii].indexOf("记录") == -1
              && typeMap.get(typeList[i]).name[ii].indexOf("时间") == -1) {
              nameList.push(typeMap.get(typeList[i]).name[ii])
              if (typeMap.get(typeList[i]).value[ii] == ""
                || typeMap.get(typeList[i]).value[ii] == null
                || typeMap.get(typeList[i]).value[ii] == undefined) {
                valueList.push("暂无")
              } else {
                valueList.push(typeMap.get(typeList[i]).value[ii])
              }
            }
          }
          obj["nameList"] = nameList
          obj["valueList"] = valueList
          this.showDeviceInfoList.push(JSON.parse(JSON.stringify(obj)))
        }
      }
      for (let i = 0; i < this.showDeviceInfoList.length; i++) {
        for (let j = 0; j < this.showDeviceInfoList[i].nameList.length; j++) {
          let obj = {};
          obj.name = this.showDeviceInfoList[i].nameList[j]
          if (this.showDeviceInfoList[i].nameList[j] == "设备状态"
            && this.showDeviceInfoList[i].valueList[j] == "正常"
            && (mapStatus != "" && mapStatus != "正常")) {
            obj.value = mapStatus
          } else {
            obj.value = this.showDeviceInfoList[i].valueList[j]
          }
          this.changedDeviceInfoList.push(obj)
        }
      }
      if (isLost) {
        let length = this.changedDeviceInfoList.length
        for (let i = 0; i < length; i++) {
          if (this.changedDeviceInfoList[i].name.indexOf("状态") == -1) {
            this.changedDeviceInfoList.splice(i, 1)
            i--
          }
        }
      }
      // 数据显示处理
      Datas.deviceAttributeName(this.changedDeviceInfoList);
    },

    // 智慧用电设备数据处理
    wisdomElectricityInfo() {
      let num = 1;
      let obj = {}
      let nameList = []
      let valueList = []
      let status = ""
      let type = ""
      for (let i = 0; i < this.deviceValueInfoList.length; i++) {
        if (this.deviceValueInfoList[i].id.indexOf("CH") != -1) {
          if (this.deviceValueInfoList[i].id.indexOf("CH" + num) != -1) {
            if (this.deviceValueInfoList[i].id.indexOf("通道类型") != -1) {
              type = this.deviceValueInfoList[i].value
            } else if (this.deviceValueInfoList[i].id.indexOf("报警") == -1
              && this.deviceValueInfoList[i].id.indexOf("故障") == -1
              && this.deviceValueInfoList[i].id.indexOf("失联") == -1
              && this.deviceValueInfoList[i].id.indexOf("开关状态") == -1) {
              if (this.deviceValueInfoList[i].value == "") {
                nameList.push(this.deviceValueInfoList[i].id)
                valueList.push("暂无")
              } else {
                nameList.push(this.deviceValueInfoList[i].id)
                valueList.push(this.deviceValueInfoList[i].value)
              }
            } else {
              status = this.deviceValueInfoList[i].value
            }
          } else {
            for (let ii = 0; ii < nameList.length; ii++) {
              if (nameList[ii].indexOf("检测值") != -1 && type != "") {
                obj["name"] = nameList[ii]
                nameList[ii] = nameList[ii].replace("检测", type);
              }
            }
            if (status != "" && status != null && status != undefined) {
              nameList.push("设备状态")
              valueList.push(status)
            } else {
              nameList.push("设备状态")
              valueList.push("离线")
            }
            obj['nameList'] = nameList
            obj['valueList'] = valueList
            let obj11 = JSON.parse(JSON.stringify(obj))
            this.wisdomElectricity.push(obj11)
            obj = {}
            nameList = []
            valueList = []
            num++;
            if (this.deviceValueInfoList[i].id.indexOf("通道类型") != -1) {
              type = this.deviceValueInfoList[i].value
            } else if (this.deviceValueInfoList[i].id.indexOf("报警") == -1
              && this.deviceValueInfoList[i].id.indexOf("故障") == -1
              && this.deviceValueInfoList[i].id.indexOf("失联") == -1
              && this.deviceValueInfoList[i].id.indexOf("开关状态") == -1
              && this.deviceValueInfoList[i].value != "") {
              nameList.push(this.deviceValueInfoList[i].id)
              valueList.push(this.deviceValueInfoList[i].value)
            } else {
              status = this.deviceValueInfoList[i].value
            }
          }
          if (i == this.deviceValueInfoList.length - 1) {
            for (let ii = 0; ii < nameList.length; ii++) {
              if (nameList[ii].indexOf("检测值") != -1 && type != "") {
                obj["name"] = nameList[ii]
                nameList[ii] = nameList[ii].replace("检测", type);
              }
            }
            if (status != "" && status != null && status != undefined) {
              nameList.push("设备状态")
              valueList.push(status)
            } else {
              nameList.push("设备状态")
              valueList.push("离线")
            }
            obj['nameList'] = nameList
            obj['valueList'] = valueList
            let obj11 = JSON.parse(JSON.stringify(obj))
            this.wisdomElectricity.push(obj11)
          }
        }
      }
      //   console.log(this.wisdomElectricity);
      for (let i = 0; i < this.wisdomElectricity.length; i++) {
        let arr = [];
        let obj1 = {};
        for (let j = 0; j < this.wisdomElectricity[i].nameList.length; j++) {
          let obj = {};
          obj.name = this.wisdomElectricity[i].nameList[j]
          obj.value = this.wisdomElectricity[i].valueList[j]
          arr.push(obj)

          // 处理CHN设备状态
          for (let k = 0; k < arr.length; k++) {
            if (arr[k]["name"] == "设备状态") {
              let aa = arr[k - 1]["name"].split('-')
              arr[k]["name"] = aa[0] + "-设备状态"
            }
          }
        }
        obj1.list = arr
        this.changedDeviceInfoList.push(obj1)
      }
    },
  },

}
</script>

<style lang="scss" scoped>
.container {
  font-family: "宋体";
  width: 100%;
  margin-top: 10px;
  padding: 10px;
  height: 300px;
  box-sizing: border-box;

  // 信息标题
  .title,
  .device-name {
    margin: 5px 0;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 2px;
    color: #a2edf7;
  }
  .title::before {
    content: "丨";
    font-size: 18px;
    font-weight: 600;
  }

  .content {
    scrollbar-width: none;
    // ##############
    .content-card {
      scrollbar-width: none;
      box-sizing: border-box;
      width: 100%;
      height: 210px;
      display: flex;
      .content-card-box2 {
        width: 20%;
        margin: 0 10px;
        box-shadow: 0px 0px 5px rgb(50, 99, 105);
        padding: 5px 10px;
        .text {
          font-size: 16px;
          color: #07a59d;
          font-weight: 600;
        }
        .box {
          width: 100%;
        }
        .box1 {
          width: 100%;
          text-align: center;
          margin-top: 80px;
          color: rgb(68, 119, 126);
          user-select: none;
        }
      }
      .content-card-box1 {
        box-shadow: 0px 0px 5px rgb(50, 99, 105);

        width: 80%;
        display: flex;
        flex-wrap: wrap;
        overflow: scroll;
        .content-card-item {
          box-sizing: border-box;
          background: rgba(3, 161, 182, 0.2);
          color: #a2edf7;
          border: none;
          margin: 5px;
          width: 24%;
          height: 130px;
          .boxes {
            display: flex;
            .img-box {
              img {
                width: 70px;
                margin: 10px 5px;
              }
            }
            .text-box {
              width: 200px;
              height: 100px;
              line-height: 100px;
              font-size: 18px;
              white-space: nowrap; //不换行
              padding-left: 10px;
              user-select: none;
            }
          }
        }
        .update-time {
          position: absolute;
          bottom: 0px;
          right: 5px;
          color: #a2edf7;
        }
      }
    }
    // 水压设备
    .shuiya {
      width: 100%;
      display: flex;

      .shuiya-left {
        width: 30%;
        height: 220px;
      }
      .shuiya-right {
        box-sizing: border-box;
        width: 70%;
        height: 220px;

        .shuiya-2 {
          box-sizing: border-box;
          margin-top: 5px;
          width: 98%;
          margin-left: 15px;
          height: 220px;

          .description {
            color: #21dbec;
            position: absolute;
            bottom: 5px;
            margin: 10px;
            letter-spacing: 2px;
          }
          .updateTime {
            color: #21dbec;
            position: absolute;
            bottom: 5px;
            right: 0;
            font-size: 16px;
            margin: 10px;
          }
          .shuiya-2-1 {
            div {
              margin: 10px;
              font-size: 18px;
              height: 30px;
              line-height: 30px;
              span {
                font-weight: 600;
              }
            }
          }
        }
      }
    }
  }
}
.addtasks {
  display: inline-block;
  float: right;
  color: #489ff0;
  cursor: pointer;
}
/deep/::-webkit-scrollbar {
  display: none;
}
/deep/.el-card__body {
  padding: 10px;
}
</style>